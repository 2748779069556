<template>
<div class="modal modal-width" id ="check_offline_modal" style="background: rgb(0 0 0 / 44%);display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
            <img src="../assets/images/Group27750.png" alt="" class="mx-auto d-block mb-3">
            <h4 class="offline-t">Do you want to go offline for whole day?</h4>
                <div class="card-select ">
                    <div class="row">
                        <div class="col-md-6">
                        <label class="cst-chk-bx text-dark">Yes
                        <input type="checkbox" :checked="offline_by_day" ref="offline_by_day_selected" :value="offline_by_day" @input="updateOfflineStatus('offline_by_day')">
                        <span class="checkmark"></span>
                        </label>
                        </div>
                        <div class="col-md-6">  
                        <label class="cst-chk-bx text-dark">No
                            <input type="checkbox" :checked="offline_by_slot" ref="offline_by_slot_selected"  :value="offline_by_slot" @input="updateOfflineStatus('offline_by_slot')">
                            <span class="checkmark"></span>
                        </label>
                        </div>  
                    </div>  
                </div>
                <div class="outer-dark-grey" v-if="offline_by_slot">
                    <span v-if="main_slots_available.length">
                        <p>Please select shift for which you are going to offline.</p>
                        <span v-for="(row,index) in main_slots_available" :key ="index">
                           <div class="chech-time" v-if="!row.status" @click.prevent="getUpdateStatus(row)">
                               <i class="fas fa-check-circle"></i>
                               {{row.start_time}} -  {{row.end_time}}
                           </div>
                           <div class="chech-time2" v-if="row.status" @click.prevent="getUpdateStatus(row)">
                               {{row.start_time}} -  {{row.end_time}}
                           </div>
                        </span>
                    </span>
                    <span v-if="!main_slots_available.length">
                        Loading Slots....
                    </span>
                </div>

                <div class="session-btn mt-5">
                <a href="#" class="cm-btn white-btn" data-dismiss="modal" @click.prevent="hideOfflineModal()"><span>Cancel</span></a>
                <a href="#" class="cm-btn" @click.prevent="saveOfflineModal()"><span>Save</span></a>
                </div>
            </div>
        </div>
  
      </div>
    </div>
  </div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import { Store } from '../resources/Store.js'
import moment from "moment-timezone";
export default {
  data() {
        return {
               Store,
               offline_by_day:false,
               offline_by_slot:true,
               current_checked_value:"offline_by_slot",
               current_available_slots:[],
               selected_slots:[],
               full_day_unavailable:null,
               main_slots_available:[],
               available_slots:[],
               unavailable_slots:[],
               offline_modal:false,
           };
  },
  mounted() {
      // this.name = this.user ? this.user.name : null;
       this.getAvailableSlots();
  },
  methods : {
      updateOfflineStatus(current_value){
      
         if(current_value == 'offline_by_slot'){
            if(this.$refs.offline_by_slot_selected.checked){
                  this.offline_by_slot = true;
                  this.offline_by_day = false;
                  this.current_checked_value = "offline_by_slot";
            }else{
                  this.offline_by_slot = false;
                  this.offline_by_day = true;
                  this.current_checked_value = "offline_by_day";
            }
         }
         if(current_value == 'offline_by_day'){

            if(this.$refs.offline_by_day_selected.checked){
                  this.offline_by_slot = false;
                  this.offline_by_day = true;
                  this.current_checked_value = "offline_by_day";
            }else{
                  this.offline_by_slot = true;
                  this.offline_by_day = false;
                  this.current_checked_value = "offline_by_slot";
            }
          //  console.log("offline_by_day",this.$refs.offline_by_day_selected.checked);
           // console.log("offline_by_slot",this.$refs.offline_by_slot_selected.checked);
         }
      },
      getAvailableSlots(){
           if(this.offline_by_slot){
                 let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                 let get_availabilty = Constant.getbaseurl()+'/web/user/availability/details?current_date='+date;
                 this.axios.get(get_availabilty)
                 .then(({data}) => {
                         let response_data = data.data;
                         this.full_day_unavailable = response_data.full_day_unavailable;
                         this.main_slots_available = response_data.main_slots_available;
                      if(this.full_day_unavailable){
                        this.offline_by_slot = false;
                        this.offline_by_day = true;
                        this.current_checked_value = "offline_by_day";
                      }else{
                         if(this.main_slots_available.length){
                              this.offline_by_slot = true;
                              this.offline_by_day = false;
                              this.current_checked_value = "offline_by_slot";
                         }
                      }
                 })
                 .catch((error) => {
                     if(error.response.status == 401){
                         this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                         Auth.logout();
                         this.$router.push('login');
                     }else{
                         this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                     }
                 });
           }
      },
      hideOfflineModal(){
         this.$emit('close',{'closer':1,'load':0});
      },
      saveOfflineModal(){
         if(this.$refs.offline_by_day_selected.checked){
                  let post_data = {
                     'availability':[{
                        'applyoption':"specific_date_unavailable",
                        'date': new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        'slots':[],
                    }],
                    'offline':1
                   };
                   let get_availabilty = Constant.getbaseurl()+'/availability/update_availability';
                   this.axios.post(get_availabilty,post_data)
                   .then(() => {
                        this.$swal({icon: 'success', title: "Availability updated successfully"});
                        console.log(window.localStorage.getItem('route'));
                        this.$router.go('/edit_availabilty');
                        this.$emit('close',{'closer':1,'load':1});
                   })
                   .catch((error) => {
                        if(error.response.status !== undefined &&(error.response.status == 401)){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                   });
         }
         if(this.$refs.offline_by_slot_selected.checked){
               //============= update unavailablity ===============//
                  let post_data = {
                     'availability':[{
                        'applyoption':"specific_date_unavailable",
                        'date': new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        'slots':this.unavailable_slots,
                    }],
                    'offline':1
                   };
                   let get_availabilty = Constant.getbaseurl()+'/availability/update_availability';
                   this.axios.post(get_availabilty,post_data)
                   .then(() => {
                      //  this.makeUserAvailable();
                        this.$swal({icon: 'success', title: "Availability updated successfully"});
                        this.$emit('close',{'closer':1,'load':1});
                   })
                   .catch((error) => {
                        if(error.response.status !== undefined &&(error.response.status == 401)){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
                 
               //============= end unavailabilty ==============//
               console.log("unavailable slots details",this.unavailable_slots);
         }
       //console.log();
      },
      makeUserAvailable(){
           let post_data = {
               'availability':[{
                  'applyoption':"full_week",
                  'date': new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                  'slots':this.available_slots,
              }]
             };
             let get_availabilty = Constant.getbaseurl()+'/availability/update_availability';
             this.axios.post(get_availabilty,post_data)
             .then(() => {
                  this.$swal({icon: 'success', title: "Availability updated successfully"});
                  this.$emit('close',{'closer':1,'load':1});
             })
             .catch((error) => {
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
            });
      },
      getUpdateStatus(row){

         if(this.main_slots_available.length){
              // let list = [];
               this.available_slots = [];
               this.unavailable_slots = [];
               this.main_slots_available.map(function(value) {
                 if(row.id == value.id){
                    if(value.status){
                       value.status = 0;
                    }else{
                       value.status = 1;
                    }
                 }
                 //list.push(value);
               });
               //this.main_slots_available = list;
               for(let key in this.main_slots_available){
                  let value = this.main_slots_available[key]
                    if(!value.status){
                       this.unavailable_slots.push({'start_time':value.actual_start_time,'end_time':value.actual_end_time});
                    }else{
                       this.available_slots.push({'start_time':value.actual_start_time,'end_time':value.actual_end_time});
                    }
                }
               console.log("unavailable slots details",this.unavailable_slots);
               console.log("available slots details",this.available_slots);
         }
      },
      formatTime(timeString) {
        return moment(timeString,"HH:mm:ss").format("hh:mm A");
      },
  },
}
</script>

