<template>
<div class="modal modal-width" id ="show_reason_modal" :style="{'background-color':background_color,'display':display_reason_color}">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
               <img src="../assets/images/issue.svg" alt="">
               <h4>Do you wish to raise an issue?</h4>
               <div class="rise-frm">
                <textarea v-model="reason" placeholder="Tell us what happened…"></textarea>
               </div>
                <div class="session-btn">
                     <a href="#" class="cm-btn decline-btn" @click.prevent="hideReasonModal()"><span>Cancel</span></a>
                    <a href="#" class="cm-btn" @click.prevent="saveReasonModal()"><span>Ok</span></a>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
  props:['show_reason_modal','display_reason_color','search_modal_details'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               reason:"",
           };
  },
  mounted(){

  },
  methods:{
    saveReasonModal(){
         console.log(this.reason,"reason")
         if(!this.reason){
                   this.$swal({icon: 'error', title: "Please provide valid reason",background:'#cb1111',color:'#fff'});
         }else{
           this.saveReasonInfo();
         }
    },
    hideReasonModal(){
         this.reason = "";
         this.$emit('closeReason',{'closer':1,'load':0});
    },
    saveReasonInfo(){
            let raise_issue = Constant.getbaseurl()+'/therapist/raise-concern';
            let post_data = {
             'appointment_id':this.search_modal_details.appointment_id,
             'therapist_id':Auth.getUser().id,
             'reason':this.reason,
            };
            this.axios.post(raise_issue,post_data)
            .then((/*{data}*/) => {
              this.reason = "";
              this.$swal({icon: 'success', title: "Issue raised to support successfully"});
              this.$emit('closeReason',{'closer':1,'load':0});
            })
            .catch((error) => {
               if(error.response.status !== undefined &&(error.response.status == 401)){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
            });
    }
  }
}
</script>


