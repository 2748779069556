<template>
 <div class="col-md-8">
      <UserCallWaitInfo style="margin-bottom:10px;" :height="22" :call_details="call_initiated_details" v-if="call_initiated_details && call_initiated_details.accepted_appointment && !call_initiated_details.accepted_appointment.actual_end_time" @move-to-call-details="moveToCallDetails"/>
      <ClientWaitMessage :client_wait="client_wait" style="margin-bottom:10px;"/>
        <div class="shadow-block">
          <div class="red-filetr" v-if="event_data">
             <h4>{{ getEventTitle(event_data.event_title) }}</h4>
             <br>
             <div class="col-md-12">
                <template v-if ="event_data.event_files.length > 0">
                  <img :src="getFileUrl(event_data.event_files)" width="100%">
                </template>
                <div class="pt-3 event_font pb-1">
                   <span><i class="fa fa-clock" aria-hidden="true"></i> {{ getEventDateTime(event_data.event_dates) }}</span>
                   <span class="float-right"><i class="fa fa-star" aria-hidden="true" style="text-transform: capitalize !important;"></i>{{ getLowerCaseLetter(event_data.type) }} Event</span>
                </div>
                <div class="pt-1 event_font pb-3">
                   <span><i class="fa fa-user-plus" aria-hidden="true"></i> {{ (event_data.event_max_attendee - event_data.event_attendees_count) }} available seats</span>
                   <span class="float-right"><i class="fa fa-users" aria-hidden="true"></i> Filling fast</span>
                </div>
             </div>
             <div><h6 style="margin-bottom: -2px;">Name:</h6>{{ event_data.event_name }}</div>
             <br>
             <div style="margin-top: -17px;"><h6 style="margin-bottom: -2px;">Title:</h6>{{ event_data.event_title }}</div>
             <br>
             <div style="margin-top: -17px;"><h6 style="margin-bottom: -2px;">Description:</h6></div>
             <div class="col-md-12"  v-html="event_data.event_description">
             </div>
          </div>
        </div>
    </div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import ClientWaitMessage from "./ClientWaitMessage.vue";
import UserCallWaitInfo from "./UserCallWaitInfo.vue";
import moment from "moment-timezone";
export default {
  props:['client_wait','intial_loaded','call_initiated_details'],
  emits:['moveToCallDetails'],
  components:{
   ClientWaitMessage,UserCallWaitInfo
  },
  methods:{
       moveToCallDetails(details){
           this.$emit('moveToCallDetails',details);
       },
       getFileUrl(file_urls){
         if(file_urls && (file_urls.length > 0)){
            console.log("file",file_urls[0].event_file_data);
            return file_urls[0].event_file_data;
         }else{
            return this.image_link;
         }
       },
       getLowerCaseLetter(title){

        let lower_case = title.toLowerCase().trim();
        
        return lower_case ? lower_case[0].toUpperCase() + lower_case.slice(1) : "";

       },
       getUpcomingEventDetails(){
             
          let getUpcomingEventDetailApi = Constant.getbaseurl()+'/event/'+this.$route.params.event_id;
          this.axios.get(getUpcomingEventDetailApi)
            .then(({data}) => {
                this.is_loaded = false;
                let response_data = data;
                if(response_data.data !== undefined){
                 this.event_data = response_data.data;
                }
          })
          .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
          });
       },
       getEventDateTime(event_dates){
           if(event_dates && (event_dates.length > 0)){
               let event_date  = event_dates[0].event_start_datetime;
               return moment(event_date).utc(event_date,"Y-M-D HH:mm").local().format("MMM Do, YYYY");
           }
       },
       getEventTitle(title = null){

          let name = title;
          let shortname = title;
          if (name.length > 100) {
               shortname = name.substring(0, 100) + " ...";
          }
          return shortname;
       }
  },
  mounted(){
    console.log(this.$route.params.event_id,"params")
    this.getUpcomingEventDetails();
  },
  data(){
    return {
      "show_control":false,
      "disable_click":true,
      "event_data":null,
      "event_list":[],
      "color":"blue",
      "image_link":"https://as1.ftcdn.net/v2/jpg/01/07/66/26/1000_F_107662666_zMxONsgMZY1rm8eshfCGMsSfmRPbMuTB.jpg",
      "pageoffset":4,
      "pageno":1
    }
  }
}
</script>

