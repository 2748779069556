<template>
   <router-view :available_status="is_available_now" :client_wait="client_wait_modal" :current_peer_id="current_peer_id" :user_media_stream="user_media_stream" :media_stream="media_stream" :partner_media_stream="partner_media_stream" @change-audio-event ="changeAudioEvent" :is_mute="is_mute" @change-video-event ="changeVideoEvent" @change-chat-event="changeChatEvent" @change-support-event ="changeSupportEvent"  @change-call-event="changeCallEvent" :is_blur="is_blur" :is_chat="is_chat" :is_support="is_support" :message_count="message_count" :call_timer="call_timer" :ongoing_call_details="ongoing_call_details" :load_data="load_data" :call_request="call_request" @current-appointment-page-id = "currentAppointmentPageId" :intial_loaded="intial_loaded" :call_initiated_details="call_initiated_details" @move-to-call-details="moveToCallDetails" :call_timer_load="call_timer_load" :current_loaded_panel="current_loaded_panel" @update-sidebar-status="updateSidebarStatus"/>
   <SearchRequestModalPage :show_search_modal="show_search_modal" :search_modal_details="search_modal_details" :display_color="display_color" @close = "closeModal" :percent="percent" :max_timeout="max_timeout" :current_percent="current_percent"/>
   <UpcomingRequestModalPage :show_upcoming_modal="show_upcoming_modal" :upcoming_modal_details="upcoming_modal_details" :display_upcoming_color="display_upcoming_color" @closeUpcomingModal = "closeUpcomingModal" v-if="!is_active_appointment_id && upcoming_modal_details"/>
   <SearchTimeoutModalPage :show_timeout_modal="show_timeout_modal" :display_timeout_color="display_timeout_color" @close-timeout = "closeTimeoutModal" :message="message"/>
   <CallInitateModalPage :show_call_modal="show_call_modal" :search_modal_details="search_modal_details" :display_call_initate_color="display_call_initate_color" @start-call-modal ="startSessionCall" />
   <RejoinCallModalPage :show_call_modal="show_call_modal" :display_call_rejoin_color="display_call_rejoin_color" @start-call-modal ="startSessionCall" />
   <SessionEndModalPage :display_call_end_color="display_call_end_color" :end_message="end_message" :current_appointment_details="current_appointment_details" @start-call-modal ="startSessionCall" @close-call-end ="closeCallEnd"/>
   <SessionAskExtendModalPage :display_call_extend_end_color="display_call_extend_end_color" :current_appointment_details="current_appointment_details" @close-call-extend ="closeCallExtend" @ask-call-extend = "askCallExtend"/>
   <SessionWaitExtendModalPage :display_call_extend_wait_color="display_call_extend_wait_color" :current_appointment_details="current_appointment_details" @close-call-wait-extend ="closeCallWaitExtend" @ask-call-wait-extend = "askCallWaitExtend"/>
   <SessionDeclineModalPage  :decline_reasons="decline_reasons" :current_appointment_details="current_appointment_details" :display_decline_reason_color="display_decline_reason_color" @close-decline-end ="closeDeclineEnd"/>
   <RiseAnIssue :show_reason_modal="show_reason_modal" :search_modal_details="search_modal_details" :display_reason_color="display_reason_color" @close-reason="closeReason"/>
   <ChatPage :is_chat="is_chat" :display_chat_color="display_chat_color" @hide-chat-modal="hideChatModal" @send-message="sendMessage" :chat_messages="chat_messages" :current_appointment_details="current_appointment_details" :user="user" :message_count="message_count"/>
</template>
<script>
   import axios from 'axios';
   import { ref } from "vue";
   import { Peer } from "peerjs";
   import moment from "moment-timezone";
   import { io } from 'socket.io-client';
   import Auth from './resources/Auth.js';
   import { getToken } from "firebase/messaging";
   import Constant from './resources/Constant.js';
   import ChatPage from '@/components/ChatPage.vue'
   import sound from './assets/sounds/new_booking.wav'
   import RiseAnIssue from '@/components/RiseAnIssue.vue'
   import { getMessaging,onMessage } from "firebase/messaging";
   import RejoinCallModalPage from '@/components/RejoinCallModalPage.vue'
   import SessionEndModalPage from '@/components/SessionEndModalPage.vue'
   import CallInitateModalPage from '@/components/CallInitateModalPage.vue'
   import SearchRequestModalPage from '@/components/SearchRequestModalPage.vue'
   import SearchTimeoutModalPage from '@/components/SearchTimeoutModalPage.vue'
   import SessionDeclineModalPage from '@/components/SessionDeclineModalPage.vue'
   import SessionAskExtendModalPage from '@/components/SessionAskExtendModalPage.vue'
   import SessionWaitExtendModalPage from '@/components/SessionWaitExtendModalPage.vue'
   
   import UpcomingRequestModalPage from '@/components/UpcomingRequestModalPage.vue'
   export default {
     components:{
       SearchRequestModalPage,SearchTimeoutModalPage,CallInitateModalPage,RiseAnIssue,ChatPage,SessionEndModalPage,SessionDeclineModalPage,SessionAskExtendModalPage,SessionWaitExtendModalPage,UpcomingRequestModalPage,RejoinCallModalPage
     },
     data(){
       return{
         web_key:process.env.VUE_APP_GOOGLE_WEB_KEYPAIR,
         user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
         show_search_modal:false,
         call_initiated_details:null,
         ping_online_user_timeout:null,
         ping_online_user_timeout_intiated:false,
         show_timeout_modal:false,
         show_reason_modal:false,
         display_color:"none",
         live_call_interval:null,
         intial_loaded:false,
         is_mute:false,
         is_blur:true,
         is_chat:false,
         is_support:false,
         call_request:false,
         client_call_interval_timeout:null,
         clientCountDownTimerInt:null,
         user_media_stream_status:true,
         partner_media_stream_status:true,
         message_count:0,
         call_timer:0,
         call_timer_load:false,
         ask_extend_timer:0,
         load_data:1,
         decline_reasons:[],
         display_chat_color:"none",
         show_upcoming_modal:false,
         clear_session:false,
         peer_disconnected_count:0,
         peer_disconnected:false,
         display_upcoming_color:"none",
         upcoming_modal_details:null,
         end_message:"",
         display_call_end_color:"none",
         display_timeout_color:"none",
         display_reason_color:"none",
         display_call_initate_color:"none",
         display_call_rejoin_color:"none",
         display_decline_reason_color:"none",
         display_call_extend_end_color:"none",
         display_call_extend_wait_color:"none",
         is_available_now : 0,
         is_action_taken_by_search_therapist_modal:0,
         is_active_search_therapist_modal:0,
         is_active_user_wait_modal:0,
         show_call_modal:1,
         partner_id:null,
         is_active_appointment_id:null,
         max_timeout:ref(30),
         ongoing_call_details:null,
         upcoming_request_detail:null,
         client_wait_modal:{'is_wait':0,'current_client_wait_time':ref(0),'total_client_wait_time':ref(30),"show_call_link":false},
         percent:ref(0),
         current_percent:ref(30),
         total_client_wait_time:ref(30),
         current_client_wait_time:ref(0),
         user_error_status_message:"Reconnecting",
         user_error_status:false,
         message:"",
         chat_messages:[],
         search_modal_details:[],
         current_appointment_details:null,
         current_peer_id:null,
         media_stream:null,
         user_media_stream:null,
         partner_media_stream:null,
         extend_call_status:0,
         extend_call_ask_status:0,
         pause_call_time:0,
         pause_call_timer:false,
         current_appointment_page_id:null,
         is_appointment_loaded:null,
         current_loaded_panel:"full",
         audio :null,
       }
     },
     mounted() {
       this.call_initiated_details = null;
       this.intial_loaded = false;
       this.call_timer_load = false;
       this.ping_online_user_timeout_intiated = false;
       this.current_appointment_page_id= this.$route.params.appointment_id;
       setTimeout(() => {
        this.intial_loaded = true;
         //console.log("intial_loaded 2000",this.intial_loaded);
       },2000);
       if(Auth.check()){
         this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
         this.connectSocket();
         this.loadServiceWorker();
         this.loadCallPermissions();
         this.getAvailabilty();
         this.setUpMessaging();
         this.checkUpcomingRequest(true);
         this.checkingForLiveCalls();
         this.checkOngoingCallSocket();
         if(this.media_stream){
           this.media_stream.getTracks().forEach((track) => {
               track.stop();
           });
         }
       }else{
         this.user = null;
         this.disconnectSocket();
       }
       this.addWindowEvents();
     },
     methods:{
       addWindowEvents(){
         window.addEventListener('offline', (event) => {
             console.log("The network connection has been lost.",event);
         });
         window.addEventListener('online', (event) => {
             this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
             if(this.user){
              // console.log("user status",this.user);
               console.log("The network connection has been online again.",event);
             }
         });
       },
       checkingForLiveCalls(){
         this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
         if (this.user){
               /*setTimeout(() => {
                  this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                   if(this.user){
                    this.checkOngoingCallForLiveCall();
                    this.checkingForLiveCalls();
                   }
               }, 10000);*/
         }
       },
       playSound(){
   
           this.audio = new Audio(sound);
           var promise = this.audio.play();
           if (promise !== undefined) {
             promise.then(success => {
               console.log("autoplay",success);
             }).catch(error => {
               console.log("no autoplay",error);
           });
         }
       },
       updateSidebarStatus(status){
               console.log("autoplay status",status);
          if(status == 'full'){
               this.current_loaded_panel = status;
          }else{
               this.current_loaded_panel = status;
          }
       },
       currentAppointmentPageId(appointment_id){
          this.current_appointment_page_id = appointment_id;
          this.checkAppointmentCallSocket();
       },
       stopSound(){
           if(this.audio){
              this.audio.pause();
              this.audio.currentTime = 0;
           }
       },
       setUpMessaging(is_load = true){
         navigator.serviceWorker.ready.then(() => { 
           getToken(getMessaging(this.$firebase_app), { vapidKey: this.web_key }).then((currentToken) => {
               if (currentToken) {
                // console.log("web app registered");
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(this.user)
                 this.updateFcmToken(currentToken);
                
               } else {
                 this.$swal({icon: 'error', title: "No registration token available. Request permission to generate one.",background:'#cb1111',color:'#fff'});
               }
             }).catch((error) => {
               console.log(error);
               if(is_load)
               this.requestPermission();
             }); 
         }).catch((error) => {
               console.log(error);
         });
       },
       changeSupportEvent(type){
           if(type == "show"){
             this.is_support = true;
             this.show_reason_modal = true;
             this.display_reason_color = "block";
           }
           if(type == "hide"){
             this.is_support = false;
             this.show_reason_modal = false;
             this.display_reason_color = "none";
           }
       },
       changeAudioEvent(type){
            if(type == "mute"){
             this.is_mute = true;
             if(this.media_stream)
             this.media_stream.getAudioTracks()[0].enabled = false;
            }
           if(type == "unmute"){
             this.is_mute = false;
             if(this.media_stream)
             this.media_stream.getAudioTracks()[0].enabled = true;
            }
       },
       changeCallEvent(event){
         console.log("event",event);
         if(this.display_decline_reason_color == 'none'){
          this.display_decline_reason_color = 'block';
         }else{
          this.display_decline_reason_color = 'none';
         }
       },
       changeVideoEvent(type){
            if(type == "blur"){
              this.is_blur = true;
              if(this.media_stream)
              this.media_stream.getVideoTracks()[0].enabled = true;
            }
           if(type == "unblur"){
              this.is_blur = false;
              if(this.media_stream)
              this.media_stream.getVideoTracks()[0].enabled = false;
            }
       },
       changeChatEvent(type){
           if(type == "show"){
             this.is_chat = true;
             this.message_count = 0;
             this.display_chat_color = "block";
           }
           if(type == "hide"){
             this.is_chat = false;
             this.message_count = 0;
             this.display_chat_color = "none";
   
           }
       },
       hideChatModal(info){
          if(info.closer == 1){
             this.message_count = 0;
             this.is_chat = false;
             this.display_chat_color = "none";
         }
       },
       loadCallPermissions(){
           /*let permission_status = false;
           let constraints = {
             audio: true,
             video: true,
           };
           navigator.mediaDevices.getUserMedia(constraints)
           .then((mediaStream) => {
             if (mediaStream.getVideoTracks().length <= 0 || mediaStream.getAudioTracks().length <= 0) {
                let video_text_message =  (mediaStream.getVideoTracks().length <= 0) ? "Video" : null;               
                let text_message =  (mediaStream.getAudioTracks().length <= 0) ? (video_text_message ? " and Audio" : "Audio") : null;
                if(text_message){
                this.$swal({icon: 'error',title:"",text:("Not able to detect "+text_message+" from your device"),background:'#cb1111',color:'#fff'});
                  permission_status = true;
                }             
             }
           })
           .catch((err) => {
              permission_status = true;
             this.$swal({icon: 'error',title:err.name,text:'Please allow audio and video permissions for call sessions',background:'#cb1111',color:'#fff'});
           });
           console.log("permission_status",permission_status);*/
       },
       loadServiceWorker(){
         if ('serviceWorker' in navigator) {
           navigator.serviceWorker.register('/firebase-messaging-sw.js').then(response => {
              let messaging = getMessaging();
                   onMessage(messaging, (payload) => {
                         //this.load_data = this.load_data + 1;
                         if(payload.notification !== undefined){
                             const notificationTitle = payload.notification.title;
                             const notificationOptions = {
                                 body:payload.notification.body,
                                 icon: payload.data.icon,
                                 vibrate: [200, 100, 200, 100, 200, 100, 200],
                                 tag: 'vibration-sample'
                            };
                         response.showNotification(notificationTitle,notificationOptions);
                       }
                   });
                 
           }).catch(error => {
             console.error('Service',error);
           })
         }
       },
       
       moveToCallDetails(details){
         console.log("current",details);
         this.startSessionCallByApp(true);
       },
       requestPermission() {
        // console.log('Requesting permission...');
             if (!("Notification" in window)) {
               this.$swal({icon: 'error', title: "This browser does not support desktop notification",background:'#cb1111',color:'#fff'});
             } else if (Notification.permission === "granted") {
               console.log("permission granted");
               //this.setUpMessaging();
                  this.setUpMessaging(false);
             } else {
               Notification.requestPermission().then((permission) => {
                 //this.$swal({icon: 'error', title: "Please allow notification for this web app",background:'#cb1111',color:'#fff'});
                 if (permission === "granted") {
                  console.log("permission granted");
                  this.setUpMessaging(false);
                 }
               });
             }
       },
       updateFcmToken(token){
            // console.log("emitted new data",date);
             if(token){
              window.localStorage.setItem('fcm_id',token);
               let update_fcm_id = Constant.getbaseurl()+'/update-fcm-id';
               let post_data = {
                'fcm_id':token
               };
               this.axios.post(update_fcm_id,post_data)
               .then((/*{data}*/) => {
                 axios.defaults.headers.common['fcm_id'] = token;
               })
               .catch((error) => {
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                     // this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
               });
         }
       },
       closeModal(info){
         if(info.closer == 1){
             this.show_search_modal = 0;
             this.display_color = "none";
            // this.is_action_taken_by_search_therapist_modal = 1;
         }
         if(info.type == 'save'){
           this.acceptUserRequest(info.data);
         }
         if(info.type == 'cancel'){
           this.declineUserRequest(info.data);
         }
       },
       closeUpcomingModal(info){
         if(info.closer == 1){
             this.upcomingScheduleRequestAction(info.type);
             this.show_upcoming_modal = 0;
             this.upcoming_modal_details = null;
             this.display_upcoming_color = "none";
         }
       },
       closeReason(info){
         if(info.closer == 1){
             this.is_support = false;
             this.display_reason_color = "none";
         }
       },
       closeDeclineEnd(info){
         if(info.closer == 1){
            // this.is_support = false;
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.show_reason_modal = false;
                       this.pause_call_timer = false;
                       this.display_color = "none";
                       this.extend_call_status = 0;
                       this.extend_call_ask_status = 0;
                       this.pause_call_timer = false;
                       this.call_request = false;
                       this.display_chat_color = "none";
                       this.display_timeout_color = "none";
                       this.display_reason_color = "none";
                       this.display_reason_color = "none";
                       this.display_call_initate_color = "none";
                       this.display_call_rejoin_color = "none";
                       this.is_mute = false;
                       this.is_blur = false;
                       this.is_support = false;
                       this.is_chat = false;
                       this.message_count = 0;
                       this.call_timer = 0;
                       this.call_timer_load = false;
                       this.is_active_user_wait_modal = 0;
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.client_wait_modal.show_call_link = false;
                       this.total_client_wait_time = ref(0);
                       this.current_client_wait_time = ref(0);
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_call_end_color = "none";
                       this.display_decline_reason_color = "none";
                       this.display_call_extend_end_color = "none";
                       this.display_call_extend_wait_color = "none";
                       if(this.media_stream){
                         this.media_stream.getTracks().forEach((track) => {
                             track.stop();
                         });
                       }
                       this.$mediaStream = null;
                       this.$mediaUserRemoteStream = null;
                       this.$mediaPartnerRemoteStream = null;
                       //this.media_stream = null;
                       this.user_media_stream = null;
                       this.partner_media_stream = null;
                       this.search_modal_details = [];
                       this.chat_messages = [];
                       this.is_chat = false;
                       this.message = "";
                       this.current_appointment_details = null;
                       this.partner_id = null;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                       this.display_decline_reason_color = "none";
                       this.checkUpcomingRequest(true);
         }else{
             this.display_decline_reason_color = "none";
         }
       },
       closeCallExtend(info){
         if(info.closer == 1){
             this.display_call_extend_end_color = "none";
             this.display_call_extend_wait_color = "none";
             this.extend_call_ask_status = 1;
             this.updateExtendedDetails(this.is_active_appointment_id,'therapist_ask_status',0);
         }
       },
       askCallExtend(/*info*/){
             this.display_call_extend_end_color = "none";
             this.display_call_extend_wait_color = "block";
             this.extend_call_ask_status = 1;
             this.updateExtendedDetails(this.is_active_appointment_id,'therapist_ask_status',1);
             this.askTherapistForExtendSession();
       },
       closeCallWaitExtend(){
   
       },
       askCallWaitExtend(){
   
       },
       closeCallEnd(data){
         if(data.closer == 1){
             this.display_call_end_color = "none";
             this.end_message = "";
         }
       },
       closeTimeoutModal(data){
         if(data.closer == 1){
             this.show_reason_modal = 0;
             this.display_color = "none";
             this.show_timeout_modal = 0;
             this.display_timeout_color = "none";
         }
       },
       disconnectSocket(){
      // console.log(" disconnectSocket user",this.user);
   
       },
       endCompletecall(){
               let reason = null;
               let post_data = {};
               post_data.appointment_id = this.current_appointment_details.id;
               post_data.is_decline = 0;
               post_data.reason = reason;
               let call_end_url = Constant.getbaseurl()+'/end-call';
               this.axios.post(call_end_url,post_data)
                 .then((/*{data}*/) => {
                     this.$swal({icon: 'success', title: "Call Ended Successfully"});
                    // let response_data = data.data;
                     this.$router.push('/');
                 })
                 .catch((error) => {
                    if(error.response.status == 401){
                        Auth.logout();
                        this.$router.push('login');
                    }else if(error.response.status == 501){
                        this.$swal({icon: 'success', title: "Call Ended Successfully"});
                    }else{
                        this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                    }
                 });
       },
       pingOnlineUser(){
         if (this.user){
             if(this.ping_online_user_timeout){
               clearTimeout(this.ping_online_user_timeout);
             }
            /* this.ping_online_user_timeout =  setTimeout(() => {
                  this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                   if(this.user){
                      let details = {'user_id':this.user.id};
                      this.$socket.emit('pingOnlineUser',details);
                      this.pingOnlineUser();
                   }
               }, 10000);*/
              }
       },
       checkOngoingCallSocket(){
             if(this.current_appointment_page_id){
   
               let onGoingSessionData = {
                    user_type: "THERAPIST",
                    appointment_id :this.current_appointment_page_id,
                    user_id:this.user ? this.user.id : null,
               };
               this.$socket.emit('check_ongoing_appointment_session',onGoingSessionData);
             }
   
       },
       checkAppointmentCallSocket(){
         //  console.log( "===================== currentAppointmentPageId",this.current_appointment_page_id);
           if(this.current_appointment_page_id !== undefined){
             if(!this.current_appointment_details){
               this.current_appointment_details = {"id":this.current_appointment_page_id};
             }
             if(this.current_appointment_page_id && !this.is_appointment_loaded){
                 let onGoingSessionData = {
                      user_type: "THERAPIST",
                      appointment_id :this.current_appointment_page_id,
                      user_id:this.user ? this.user.id : null,
                 };
                 this.is_appointment_loaded = this.current_appointment_page_id;
                 this.$socket.emit('check_live_appointment',onGoingSessionData);
             }
           }else{
             this.checkOngoingCallForLiveCall();
           }
   
       },
       connectSocket(){
           try{
             this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
             if(this.user){
               console.log("user status",this.user);
                 this.$socket = io(process.env.VUE_APP_SOCKET_URL+""+this.user.id, {
                   transports:['websocket'],
                   cors: {
                     origin: "https://therapist.naqqe.app"
                   }
                 });
                 this.$socket.on("connect", () => {
                       console.log("socket_id connected",this.$socket.id); // x8WIv7-mJelg7on_ALbx
                       this.checkAppointmentCallSocket();
                       this.$socket.on("disconnect", (error) => {
                       console.log("socket id disconnect error",error); // x8WIv7-mJelg7on_ALbx
                       });
                 });
                this.$socket.on("UserAvailableEvent", (payload) => {
                    //console.log("available_status",payload);
                    if((payload !== undefined) && (payload.event_name !== undefined) && (payload.online_users !== undefined)){
                        let online_users = payload.online_users;
                        let data_users = online_users.split(',');
                        let user_id = this.user ? this.user.id : "0";
                        if(data_users.indexOf(user_id.toString()) >= 0){
                               this.is_available_now = 1;
                               this.$isAvailable = 1;
                        }else{
                                this.is_available_now = 0;
                                this.$isAvailable = 0;
                        }
                    }
                 });
                 
                this.$socket.on("userTherapistRequest", (payload) => {
                 console.log("userTherapistRequest".payload);
                  this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                  if(!this.is_active_search_therapist_modal && this.user){
                    this.is_active_appointment_id = payload.appointment_id;
                    this.is_active_search_therapist_modal = 1;
                    this.search_modal_details = payload;
                    this.is_active_user_wait_modal = 1;
                    this.extend_call_ask_status = 0;
                    this.extend_call_status = 0;
                    this.call_timer = 0;
                    this.call_timer_load = false;
                    this.display_timeout_color = "none";
                    this.call_request = true;
                    this.end_message = "";
                    this.upcoming_modal_details = null;
                    this.display_upcoming_color = "none";
                    this.display_call_end_color = "none";
                    this.display_decline_reason_color = "none";
                    this.display_call_extend_end_color = "none";
                    this.display_call_extend_wait_color = "none";
                    this.show_timeout_modal = false;
                    this.show_search_modal = true;
                    this.current_percent = ref(30);
                    this.display_color = "block";
                    this.max_timeout = ref(30);
                    this.chat_messages = [];
                    this.percent = ref(0);
                    this.countDownTimer();
                    this.playSound();
                    this.is_chat = 0;
   
                  }
               });
   
               this.$socket.on("requestActionByTherapist", (payload) => {
               
              /*   console.log("requestActionByTherapist",payload);
                 console.log("is_active_appointment_id",this.is_active_appointment_id);
                 console.log("is_active_search_therapist_modal",this.is_active_search_therapist_modal);*/
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id)){
                    if(payload && payload.statuscode == 501 && (this.is_active_search_therapist_modal || this.is_active_user_wait_modal)){
   
                       if(payload.therapist_ids){
                             let therapists = payload.therapist_ids.split(",");
                            // let current_therapists = therapists.includes(String(this.user.id));
                             if(!therapists.includes(String(this.user.id))){
                               this.is_action_taken_by_search_therapist_modal = null;
                               this.is_active_search_therapist_modal = 0;
                               this.is_active_appointment_id = null;
                               this.call_initiated_details = null;
                               this.show_search_modal = false;
                               this.show_timeout_modal = true;
                               this.call_request = false;
                               this.client_wait_modal.is_wait = 0;
                               this.client_wait_modal.total_client_wait_time = 0;
                               this.client_wait_modal.current_client_wait_time = 0;
                               this.display_timeout_color = "block";
                               this.display_color = "none";
                               this.display_upcoming_color = "none";
                               this.message = "You take too long to respond.The requset has been timed out.";
                             }
                       }else{
                               this.is_action_taken_by_search_therapist_modal = null;
                               this.is_active_search_therapist_modal = 0;
                               this.is_active_appointment_id = null;
                               this.call_initiated_details = null;
                               this.show_search_modal = false;
                               this.show_timeout_modal = true;
                               this.client_wait_modal.is_wait = 0;
                               this.client_wait_modal.total_client_wait_time = 0;
                               this.client_wait_modal.current_client_wait_time = 0;
                               this.display_timeout_color = "block";
                               this.call_request = false;
                               this.display_color = "none";
                               this.display_upcoming_color = "none";
                               this.message = "You take too long to respond.The requset has been timed out.";
                       }
   
                    }
                    if(payload && payload.statuscode == 504 && (this.is_active_search_therapist_modal  || this.is_active_user_wait_modal)){
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.call_request = false;
                       this.message = "You are too late for accepting the job request. Don't worry there is more opportunities are coming";
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
   
                    }
                    if(payload && (payload.statuscode == 502 || payload.statuscode == 503) && (this.is_active_search_therapist_modal  || this.is_active_user_wait_modal)){
                     
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.call_request = false;
                       this.message = payload.message;
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
   
                    }
                    if(payload && (payload.statuscode == 200) && (this.is_active_search_therapist_modal) && ((payload.accepted_status != undefined) && payload.accepted_status)) {
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_user_wait_modal = 1;
                       this.is_active_appointment_id = payload.appointment_id;
                       //this.is_active_search_therapist_modal = payload.appointment_id;
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                       this.call_request = true;
                       this.total_client_wait_time = ref(payload.timer_interval);
                       this.current_client_wait_time = ref(0);
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.client_wait_modal.is_wait = 1;
                    }
                    
                 }
   
                 if(this.audio){
                   this.stopSound();
                 }
               });
               this.$socket.on("requestEnableTimer",(payload) => {
                // console.log("requestEnableTimer",payload);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.statuscode == 505)){
                   if(payload && (this.is_active_search_therapist_modal || this.is_active_user_wait_modal)){
                       //let current_utc_date = moment.utc().format("Y-M-D HH:mm:ss");
                     //  let total_time = moment(current_utc_date,'YYYY-MM-DD HH:mm:ss').diff(moment(response_data.therapist_ask_status_time,'YYYY-MM-DD HH:mm:ss'), 'seconds');
                       this.is_active_user_wait_modal = 1;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = ref(0);
                       this.client_wait_modal.total_client_wait_time = ref(0);
                       this.total_client_wait_time = ref(parseInt(payload.timer_interval));
                       this.current_client_wait_time = ref(0);
                       this.client_wait_modal.is_wait = 1;
                       if(this.clientCountDownTimerInt)
                       clearTimeout(this.clientCountDownTimerInt);
                       this.clientCountDownTimer();
                   }
                 }
               });
               this.$socket.on("ExtendCallRequestByUser",(payload) => {
                 console.log("ExtendCallRequestByUser",payload);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.statuscode == 509) && (this.user.id == payload.therapist_id)){
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = ref(0);
                       this.client_wait_modal.total_client_wait_time = ref(0);
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.pause_call_timer = true;
                       this.message = this.current_appointment_details.user.name + " has accepted the extend session request and making payment.Please wait till payment completes.";
                 }
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.statuscode == 510) && (this.user.id == payload.therapist_id)){
                       this.client_wait_modal.current_client_wait_time = ref(0);
                       this.client_wait_modal.total_client_wait_time = ref(0);
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.message = this.current_appointment_details.user.name + " has declined the extend session request.";
                       this.checkOnlineCallTimer();
                 }
               });
               
               this.$socket.on("videoCallDataExchange",(payload) => {
                  console.log("videoCallDataExchange",payload);
                  this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                   if(payload && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.type == 'PAYMENT_FAILED')){
                       this.is_active_user_wait_modal = 0;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.message = "The user was not able to complete the payment, so we are decling the extend request.Please continue with session";
                       this.checkOnlineCallTimer();
                   }
                   if(payload && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.type == 'INCOMING_CALL')){
                       this.is_active_user_wait_modal = 0;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                       if(payload.callOngoing){
                         this.show_timeout_modal = true;
                         this.display_timeout_color = "block";
                         if(payload.from == 'PARTNER'){
                           this.message = "Please wait, the partner is on another call";
                         }
                         if(payload.from == 'USER'){
                           this.message = "Please wait, the user is on another call";
                         }
                       }else{
                         this.show_timeout_modal = false;
                         this.display_timeout_color = "none";
                         this.message = "";
                       }
                       this.checkOnlineCallTimer();
                   }
               });
               this.$socket.on("ExtendCallRequestAction",(payload) => {
                 console.log("ExtendCallRequestAction",payload);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload && this.user && (payload.appointment_id == this.is_active_appointment_id) && (this.user.id == payload.therapist_id)){
                       this.is_active_user_wait_modal = 0;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                       this.extend_call_status = 1;
                       this.extend_call_ask_status = 1;
                       this.updateExtendedDetails(this.is_active_appointment_id,'appointment_extend_payment_status',1);
                       this.getOngoingCallInfo();
                   }
               });
               this.$socket.on("cancelExtendedSessionRequest",(payload) => {
                 console.log("cancelExtendedSessionRequest",payload);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload && this.user && (payload.appointment_id == this.is_active_appointment_id) && (this.user.id == payload.therapist_id)){
                       this.is_active_user_wait_modal = 1;
                       this.extend_call_status = 0;
                       this.extend_call_ask_status = 1;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.display_call_extend_wait_color = 'none';
                       this.display_call_extend_end_color = 'none';
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.message = "Due to delay in payment, we are decling the extend request.Please continue with session";
                       this.updateExtendedDetails(this.is_active_appointment_id,'appointment_extend_payment_status',3);
                       this.checkOnlineCallTimer();
                   }
               });
               this.$socket.on("userTherapistRequestTimeOut",(payload) => {
                
               //  console.log("userTherapistRequestTimeOut",payload);
                 console.log("userTherapistRequestTimeOut extend call",this.extend_call_status);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id) && (payload.statuscode == 506)){
                   if(payload && (this.is_active_search_therapist_modal || this.is_active_user_wait_modal)){
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_user_wait_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                       this.call_request = false;
                       this.message = "Due to delay in payment, we are decling the job request. Don't worry there is more opportunities are coming.";
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                   }
                 }
                  if(this.audio){
                   this.stopSound();
                 }
               }); 
               this.$socket.on("selectedTherapistsByUser",(payload) => {
                 console.log("selectedTherapistsByUser",payload.call_Details);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(this.user){
                   if(payload.call_Details != undefined && payload.call_Details.type == "upcoming_meeting"){
                        this.checkUpcomingRequest(true);
                   }else{
                       if((this.is_active_search_therapist_modal || this.is_active_user_wait_modal)){
                           this.is_active_user_wait_modal = 0;
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = ref(0);
                           this.client_wait_modal.total_client_wait_time = ref(0);
                           this.total_client_wait_time = ref(0);
                           this.current_client_wait_time = ref(0);
                           this.is_action_taken_by_search_therapist_modal = null;
                           this.is_active_search_therapist_modal = 1;
                           this.is_active_appointment_id = null;
                           this.call_initiated_details = null;
                           this.show_search_modal = false;
                           this.show_timeout_modal = false;
                           this.display_timeout_color = "none";
                           this.display_color = "none";
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.total_client_wait_time = ref(0);
                           this.current_client_wait_time = ref(0);
                           this.display_call_rejoin_color = "none";
                           this.display_call_initate_color = "block";
                           this.getAppointmentDetails(this.search_modal_details.appointment_id);
                       }
                   }
                 }
               });
               this.$socket.on("rejectedTherapistsByUser",(payload) => {
                  console.log("rejectedTherapistsByUser",payload);
                  this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                   if((this.is_active_search_therapist_modal || this.is_active_user_wait_modal) && this.user){
                       this.is_active_user_wait_modal = 0;
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.total_client_wait_time = ref(0);
                       this.current_client_wait_time = ref(0);
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                       this.display_call_initate_color = "none";
                       this.display_call_rejoin_color = "none";
                       this.display_color = "none";
                       this.call_request = false;
                       this.message = "The user has decided an alternative therapist. Please stay online for more opportunities";
                   }
               });    
                
               this.$socket.on("update_ongoing_appointment_session",(payload) => {
                // console.log("update_ongoing_appointment_session",payload.details);
                   let ongoing_call_details = payload;
                   if(payload.details !== undefined){
                   //  console.log("update_ongoing_appointment_session vreqqw",payload.details);
                     if(ongoing_call_details && ongoing_call_details.details.total_call_time){
                       this.call_timer = ongoing_call_details.details.call_timer - ongoing_call_details.details.total_call_time;
                       this.pause_call_timer = false;
                     }
                     if(!this.client_call_interval_timeout){
                       if(parseInt(this.call_timer) > 0){
                         let appointment_details = (ongoing_call_details.appointment !== undefined ? ongoing_call_details.appointment : null);
                         if(appointment_details && (appointment_details[0].extend_request_status == 1) && (appointment_details[0].extended_request_time) && (!appointment_details[0].is_extended)){
                            this.countDownCallTimer(true);
                         }else{
                            this.countDownCallTimer();
                         }
                       }
                     }
                   }else{
                       clearTimeout(this.client_call_interval_timeout);
                       this.client_call_interval_timeout = null;
                   }
               });
               this.$socket.on("update_live_appointment",(payload) => {
                     if((payload.status_code != undefined) && (payload.status_code == 401)){
                        if((this.$route != undefined) && (this.$route.name == 'session_call')){
                         this.$router.push('/');
                         this.$swal({icon: 'error', title: "No live appointment found",background:'#cb1111',color:'#fff'});
                        }
                     }
   
                     if((payload.status_code != undefined) && (payload.status_code == 200)){
                       let appointment_details = payload.appointment.length ? payload.appointment[0] : null;
                       let call_details = payload.details ? payload.details : null;
                       if(appointment_details && appointment_details.actual_end_time){
                         this.$router.push('/');
                         this.$swal({icon: 'error', title: "Appointment already ended",background:'#cb1111',color:'#fff'});
                       }
                       if(appointment_details && appointment_details.actual_end_time){
                         this.$router.push('/');
                         this.$swal({icon: 'error', title: "Appointment already ended",background:'#cb1111',color:'#fff'});
                       }
                       if(appointment_details && (appointment_details.actual_start_time && !appointment_details.actual_end_time) && appointment_details.is_accepted){
                           let total_call_timer = call_details.call_timer;
                          
                           if(appointment_details.is_extended){
                               total_call_timer = (parseInt(call_details.call_timer) + parseInt(call_details.extend_call_timer));
                           }
                           if(!appointment_details.is_extended){
                             total_call_timer = parseInt(call_details.call_timer);
                           }
                           let total_time = moment(call_details.current_datetime,'YYYY-MM-DD HH:mm:ss').diff(moment(appointment_details.start_time,'YYYY-MM-DD HH:mm:ss'), 'seconds');
                           if(total_time < total_call_timer){
                             this.getAppointmentDetails(appointment_details.appointment_id,true);
                           }else{
                               this.endCompletecall();
                               this.is_active_user_wait_modal = 0;
                               this.client_wait_modal.is_wait = 0;
                               this.client_wait_modal.current_client_wait_time = 0;
                               this.client_wait_modal.total_client_wait_time = 0;
                               this.total_client_wait_time = ref(0);
                               this.current_client_wait_time = ref(0);
                               this.is_action_taken_by_search_therapist_modal = null;
                               this.is_active_search_therapist_modal = 0;
                               this.is_chat = false;
                               this.is_active_appointment_id = null;
                               this.call_initiated_details = null;
                               this.show_search_modal = false;
                               this.pause_call_timer = false;
                               this.call_timer = 0;
                               this.call_timer_load = false;
                               this.extend_call_status = 0;
                               this.extend_call_ask_status = 0;
                               this.show_timeout_modal = false;
                               this.end_message = "Would you like to recommend an article for "+appointment_details.user.name;
                               this.display_decline_reason_color = "none";
                               this.display_call_extend_end_color = "none";
                               this.display_call_extend_wait_color = "none";
                               this.display_timeout_color = "none";
                               this.display_color = "none";
                               if(this.media_stream){
                                 this.media_stream.getTracks().forEach((track) => {
                                     track.stop();
                                 });
                               }
                               this.call_request = false;
                               this.$mediaStream = null;
                               this.$mediaUserRemoteStream = null;
                               this.$mediaPartnerRemoteStream = null;
                               this.user_media_stream = null;
                               this.partner_media_stream = null;
                               this.search_modal_details = [];
                               this.chat_messages = [];
                               this.current_appointment_details = null;
                               this.partner_id = null;
                               this.$router.push('/');
                               this.display_call_end_color = "block";
                               this.$router.push('/');
                               this.$swal({icon: 'error', title: "Appointment call ended",background:'#cb1111',color:'#fff'});
                           }
                       }
                     }
                     
               });
   
               this.$socket.on("abortUserRequest",(payload) => {
                  console.log("abortUserRequest",payload);
                 if(this.audio){
                   this.stopSound();
                 }
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined &&  this.user && (payload.appointment_id == this.is_active_appointment_id)){
                   if(payload && (this.is_active_search_therapist_modal || this.is_active_user_wait_modal)){
                      this.show_search_modal = false;
                      this.show_timeout_modal = false;
                      this.show_reason_modal = false;
                      this.pause_call_timer = false;
                      this.display_color = "none";
                      this.call_request = false;
                      this.display_chat_color = "none";
                      this.display_timeout_color = "none";
                      this.display_reason_color = "none";
                      this.display_reason_color = "none";
                      this.display_call_initate_color = "none";
                      this.display_call_rejoin_color = "none";
                      this.is_mute = false;
                      this.is_blur = false;
                      this.is_support = false;
                      this.call_request = false;
                      this.is_chat = false;
                      this.message_count = 0;
                      this.call_timer = 0;
                      this.call_timer_load = false;
                      this.extend_call_status = 0;
                      this.extend_call_ask_status = 0;
                       this.is_active_user_wait_modal = 0;
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.total_client_wait_time = ref(0);
                       this.current_client_wait_time = ref(0);
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.message = payload.message;
                       this.display_call_end_color = "none";
                       this.display_decline_reason_color = "none";
                       this.display_call_extend_end_color = "none";
                       this.display_call_extend_wait_color = "none";
                       this.show_timeout_modal = true;
                       this.display_timeout_color = "block";
                   }
                 }
               });
               this.$socket.on("abortTherapistRequest",(payload) => {
                 console.log("abortTherapistRequest",payload);
                 if(this.audio){
                   this.stopSound();
                 }
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && this.user && (payload.appointment_id == this.is_active_appointment_id)){
                  if(payload && (this.is_active_search_therapist_modal || this.is_active_user_wait_modal) && ((payload.accepted_status != undefined) && payload.accepted_status)){
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_user_wait_modal = 1;
                       this.is_active_appointment_id = payload.appointment_id;
                       this.is_active_search_therapist_modal = 0;
                       this.show_search_modal = false;
                       this.show_timeout_modal = false;
                       this.call_request = false;
                       this.display_timeout_color = "none";
                       this.display_color = "none";
                    
                   }
                 }
               });
               this.$socket.on("callEndedByUser",(payload) => {
                 console.log("callEndedByUser",payload);
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && (this.user) && (payload.appointment_id == this.is_active_appointment_id)){
                  //===== hello ///
                       this.endCompletecall();
                      this.show_search_modal = false;
                      this.show_timeout_modal = false;
                      this.show_reason_modal = false;
                      this.pause_call_timer = false;
                      this.display_color = "none";
                      this.call_request = false;
                      this.display_chat_color = "none";
                      this.display_timeout_color = "none";
                      this.display_reason_color = "none";
                      this.display_reason_color = "none";
                      this.display_call_initate_color = "none";
                      this.display_call_rejoin_color = "none";
                      this.is_mute = false;
                      this.is_blur = false;
                      this.is_support = false;
                      this.call_request = false;
                      this.is_chat = false;
                      this.message_count = 0;
                      this.call_timer = 0;
                      this.call_timer_load = false;
                      this.extend_call_status = 0;
                      this.extend_call_ask_status = 0;
                       this.is_active_user_wait_modal = 0;
                       this.client_wait_modal.is_wait = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       this.total_client_wait_time = ref(0);
                       this.current_client_wait_time = ref(0);
                       this.is_action_taken_by_search_therapist_modal = null;
                       this.is_active_search_therapist_modal = 0;
                       this.is_active_appointment_id = null;
                       this.call_initiated_details = null;
                       this.show_search_modal = false;
                       this.show_timeout_modal = true;
                       this.display_call_end_color = "none";
                       this.display_decline_reason_color = "none";
                       this.display_call_extend_end_color = "none";
                       this.display_call_extend_wait_color = "none";
                      
                       this.media_stream.getTracks().forEach((track) => {
                           track.stop();
                       });
                       this.$mediaStream = null;
                       this.$mediaUserRemoteStream = null;
                       this.$mediaPartnerRemoteStream = null;
                       //this.media_stream = null;
                       this.user_media_stream = null;
                       this.partner_media_stream = null;
                       this.search_modal_details = [];
                       this.chat_messages = [];
                       this.is_chat = false;
                       this.message = this.current_appointment_details.user.name+" has ended the session early, Please stay online for more opportunities";
                       this.current_appointment_details = null;
                       this.$router.push('/');
                       this.display_timeout_color = "block";
                       this.display_color = "none";
                 }
               });
               this.$socket.on("userTherapistCallTimeOut",(payload) => {
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if(payload.appointment_id != undefined && this.user && (payload.appointment_id == this.is_active_appointment_id)){
                  //===== hello ///
                       if((payload.is_extended != this.extend_call_status)){
                            console.log("call ended");
                       }
                       if(payload.is_extended  == this.extend_call_status){
                           this.endCompletecall();
                           this.is_active_user_wait_modal = 0;
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.total_client_wait_time = ref(0);
                           this.current_client_wait_time = ref(0);
                           this.is_action_taken_by_search_therapist_modal = null;
                           this.is_active_search_therapist_modal = 0;
                           this.is_chat = false;
                           this.is_active_appointment_id = null;
                           this.call_initiated_details = null;
                           this.show_search_modal = false;
                           this.pause_call_timer = false;
                           this.call_timer = 0;
                           this.call_timer_load = false;
                           this.extend_call_status = 0;
                           this.extend_call_ask_status = 0;
                           this.show_timeout_modal = false;
                           this.end_message = "Would you like to recommend an article for "+this.current_appointment_details.user.name;
                           this.display_decline_reason_color = "none";
                           this.display_call_extend_end_color = "none";
                           this.display_call_extend_wait_color = "none";
                           this.display_timeout_color = "none";
                           this.display_color = "none";
                           this.media_stream.getTracks().forEach((track) => {
                               track.stop();
                           });
                           this.call_request = false;
                           this.$mediaStream = null;
                           this.$mediaUserRemoteStream = null;
                           this.$mediaPartnerRemoteStream = null;
                           this.user_media_stream = null;
                           this.partner_media_stream = null;
                           this.search_modal_details = [];
                           this.chat_messages = [];
                           this.current_appointment_details = null;
                           this.partner_id = null;
                           this.$router.push('/');
                           this.display_call_end_color = "block";
                       }
                 }
               });
               this.$socket.on('emitPeerJsToken', (payload) => {
                 console.log("emitPeerJsToken",payload);
                 console.log("this.media_stream",this.media_stream);
   
                 if(!this.media_stream || (this.media_stream && !this.media_stream.active)){
                   navigator.mediaDevices.getUserMedia({video:true, audio: true }).then((media) => {
                     this.$mediaStream = media
                     this.media_stream = media;
                   }).catch((err) => {
                        this.$swal({icon: 'error', title: err,background:'#cb1111',color:'#fff'});
                   });
                 }else{
                 this.callRemoteUser(payload.peer_id, payload.sendername, payload.provider_type);
                 }
               });
               this.$socket.on('checkPingUser',(payload) => {
                 console.log("user is pinging out",payload);
   
               });
               this.$socket.on('callInitiated',(payload) => {
                 console.log('on call intial_loaded',payload);
               });
               this.$socket.on('readMessage',(payload) => {
                 if(payload.senderId != this.user.id){
                   this.message_count++;
                 }
                 this.chat_messages.push(payload);
                 console.log("user is readMessage out",payload);
               });
               this.$socket.on('sendMessage',(payload) => {
                 //this.chat_messages.push(payload);
                 console.log("user is sendMessage out",payload);
               });
   
               this.$socket.on('updateBlurAction',(payload) => {
                 console.log("emit socket updateBlurAction",payload);
                 if(payload.provider_type !== undefined){
                   if (payload.provider_type == 'USER') {
                     if(this.user_media_stream)
                     this.user_media_stream.blur = payload.video;
                   } else {
                    if(this.partner_media_stream)
                    this.partner_media_stream.blur = payload.video;
                   }
                 }else{
                   if(this.user_media_stream)
                   this.user_media_stream.blur = payload.video;
                 }
               });
               
               this.$socket.on("socket_disconnected", (payload) => {
                 this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
                 if((payload.userId !== undefined) && (this.user && (this.user.id == payload.userId))){
                   this.$socket.connect();
                  // this.checkOngoingCallSocket();
                   this.checkAppointmentCallSocket();
                 }
                 console.log("disconnected payload",payload); // undefined
               });
             }
           }
           catch(err) {
             console.log("err in socket",err);
           }
       },
       reconnectPeerjs(){
   
           let peer_user_id   = this.generateUUID();
           this.$peerInstance = new Peer(peer_user_id,{
               host: process.env.VUE_APP_PEER_SERVER,
               path: '/',
               secure:true,
               debug:1,
               config: {
                 "iceServers": [
                   {
                     'urls': process.env.VUE_APP_TURN,
                     'username': process.env.VUE_APP_PEER_USER,
                     "iceTransportPolicy": 'relay',
                     'credential':process.env.VUE_APP_PEER_PWD,
                   },
                   {
                     'urls': process.env.VUE_APP_STUN,
                     'username': process.env.VUE_APP_PEER_USER,
                     "iceTransportPolicy": 'relay',
                     'credential':process.env.VUE_APP_PEER_PWD,
                   }
                 ]
               },
           });
       },
       connectPeerjs(start_call = false,enable_timer = false){
             let peer_user_id = this.generateUUID();
             this.$peerInstance = new Peer(peer_user_id,{
               host: process.env.VUE_APP_PEER_SERVER,
               path: '/',
               debug:4,
               secure:true,
               config: {
                   "iceServers": [
                     {
                       'urls': process.env.VUE_APP_TURN,
                       'username': process.env.VUE_APP_PEER_USER,
                       "iceTransportPolicy": 'relay',
                       'credential':process.env.VUE_APP_PEER_PWD,
                     },
                     {
                       'urls': process.env.VUE_APP_STUN,
                       'username': process.env.VUE_APP_PEER_USER,
                       "iceTransportPolicy": 'relay',
                       'credential':process.env.VUE_APP_PEER_PWD,
                     }
                   ]
                 },
             });

             this.$peerInstance.on("open", (peer_id) => {
             this.$peerId = peer_id;
             this.current_peer_id = peer_id;
   
             let savePeerJsTokenDataForUser = {
                  receiver_id: this.search_modal_details.user_id,
                  peer_id: peer_id,
                  user_id: this.user.id,
                  appointment_id: this.search_modal_details.appointment_id,
                  sender_name: this.user.name,
                  provider_type: 'THERAPIST',
             };
   
             let callIntiatedData = {
                  user_id: this.search_modal_details.user_id,
                  partner_id:this.partner_id,
                  videoDetails:{
                      sessionStarted:true,
                      appointment_id:this.search_modal_details.appointment_id,
                  }
             };
             this.$socket.emit('savePeerJsToken',savePeerJsTokenDataForUser);

             this.$socket.emit('callInitiated',callIntiatedData);
             if (this.partner_id){
               let savePeerJsTokenDataForPartner = {
                  receiver_id: this.partner_id,
                  peer_id: peer_id,
                  user_id: this.user.id,
                  appointment_id: this.search_modal_details.appointment_id,
                  sender_name: this.user.name,
                  provider_type: 'THERAPIST',
               };
               this.$socket.emit('callInitiated',callIntiatedData);
               this.$socket.emit('savePeerJsToken',savePeerJsTokenDataForPartner);
             }
           });
           this.$peerInstance.on("connection", (connection) =>{
               console.log("peer connection establishment",connection);
             //  this.reconnectPeerConnection();
           });
           
           this.$peerInstance.on("disconnected", (error) =>{
               console.log("peer disconnected by disconnect",error);
            //   this.reconnectPeerConnection();
           });
           this.$peerInstance.on("error", (error) =>{
               console.log("peer disconnected by error",error.type);
             //  this.reconnectPeerConnection();
   
           });
           this.$peerInstance.on("close", (error) =>{
               console.log("peer disconnected by close",error);
               //this.reconnectPeerConnection();
   
           });
           console.log("error data",this.$mediaStream);
           this.$peerInstance.on('call', (call) => {
                     call.answer(this.$mediaStream);
                     call.on('stream', (remoteMediaStream) => {
                         console.log("call details",remoteMediaStream);
                         if(call.metadata.provider_type == 'USER'){
                          this.$mediaUserRemoteStream = {
                                 remoteStream: remoteMediaStream,
                                 peer_id: call.metadata.remotePeerId,
                                 name: call.metadata.sender_name,
                                 provider: call.metadata.provider_type,
                                 blur:this.user_media_stream_status,
                               };
                           this.user_media_stream = {
                                 remoteStream: remoteMediaStream,
                                 peer_id: call.metadata.remotePeerId,
                                 name: call.metadata.sender_name,
                                 provider: call.metadata.provider_type,
                                 blur:this.user_media_stream_status,
                               };
                         }else if(call.metadata.provider_type == 'PARTNER'){
                           this.$mediaPartnerRemoteStream = {
                                 remoteStream: remoteMediaStream,
                                 peer_id: call.metadata.remotePeerId,
                                 name: call.metadata.sender_name,
                                 provider: call.metadata.provider_type,
                                 blur:this.partner_media_stream_status,
                               };
                           this.partner_media_stream = {
                                 remoteStream: remoteMediaStream,
                                 peer_id: call.metadata.remotePeerId,
                                 name: call.metadata.sender_name,
                                 provider: call.metadata.provider_type,
                                 blur:this.partner_media_stream_status,
                               };
                         }
                     });
                     call.on('data', (remoteMediaStream) => {
                         console.log("data",remoteMediaStream);
                     });
                     call.on('disconnected', (info) => {
                       console.log("disconnected",call);
                       console.log("disconnected  info",info);
                       this.removeVideoStream(call.metadata.remotePeerId);
                     });
                     call.on('close', (info) => {
                       console.log("close",call);
                       console.log("close  info",info);
                       this.removeVideoStream(call.metadata.remotePeerId);
                     });
                     call.on('error', (info) => {
                        console.log("error",call);
                       console.log("error  info",info);
                       this.removeVideoStream(call.metadata.remotePeerId);
                     });
           });
           if(start_call){
             this.checkOngoingCall(enable_timer);
           }
       },
       reconnectPeerConnection(){
        
           this.peer_disconnected_count = 1;
           this.peer_disconnected = true;
           this.connectPeerjs();
       },
       removeVideoStream(peer_id){
              if(this.$mediaUserRemoteStream && (this.$mediaUserRemoteStream.peer_id == peer_id)){
                   this.$mediaUserRemoteStream.remoteStream = null;
                   this.user_media_stream.remoteStream = null;
              }
              if(this.$mediaPartnerRemoteStream && (this.$mediaPartnerRemoteStream.peer_id == peer_id)){
                   this.$mediaPartnerRemoteStream.remoteStream = null;
                   this.partner_media_stream.remoteStream = null;
              }
       },
       callRemoteUser(remotePeerId, name, provider){
          let call_uniqid = this.generateUUID();
           if(this.$peerInstance){
             let call = this.$peerInstance.call(remotePeerId,this.$mediaStream, {
               metadata: {
                 remotePeerId: call_uniqid,
                 sender_name: this.user.name,
                 provider_type: 'THERAPIST',
                 currentState: {
                   userBlurr: false,
                   partnerBlurr: false,
                   fullScreenBlurr: false,
                 }
               },
             });
             call.on('stream', (remoteMediaStream) => {
                   if(provider == 'USER'){
                      this.$mediaUserRemoteStream = {
                             remoteStream: remoteMediaStream,
                             peer_id: call.metadata.remotePeerId,
                             name: name,
                             provider:provider,
                             blur:this.user_media_stream_status
                           };
                       this.user_media_stream = {
                             remoteStream: remoteMediaStream,
                             peer_id: call.metadata.remotePeerId,
                             name: name,
                             provider:provider,
                             blur:this.user_media_stream_status
                           };
                     }else if(provider == 'PARTNER'){
                       this.$mediaPartnerRemoteStream = {
                             remoteStream: remoteMediaStream,
                             peer_id: call.metadata.remotePeerId,
                             name: name,
                             provider:provider,
                             blur:this.partner_media_stream_status
                           };
                       this.partner_media_stream = {
                             remoteStream: remoteMediaStream,
                             peer_id: call.metadata.remotePeerId,
                             name: name,
                             provider:provider,
                             blur:this.partner_media_stream_status
                           };
                     }
                   
             });
             call.on('disconnected', (error) => {
                  console.log("call user disconnected",error);
                 this.removeVideoStream(call.metadata.remotePeerId);
             });
             call.on('close', (error) => {
                  console.log("call user close",error);
                 this.removeVideoStream(call.metadata.remotePeerId);
             });
             call.on('error', (error) => {
                  console.log("call user error",error);
                 this.removeVideoStream(call.metadata.remotePeerId);
             });
   
           }else{
              if(this.is_active_appointment_id){
                 this.startSessionCallByApp(true)
              }
           }
       },
       generateUUID() { // Public Domain/MIT
           var d = new Date().getTime();//Timestamp
           var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
           return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
               var r = Math.random() * 16;//random number between 0 and 16
               if(d > 0){//Use timestamp until depleted
                   r = (d + r)%16 | 0;
                   d = Math.floor(d/16);
               } else {//Use microseconds since page-load if supported
                   r = (d2 + r)%16 | 0;
                   d2 = Math.floor(d2/16);
               }
               return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
           });
       },
       acceptUserRequest(details){
        
         let request_data = {
            'appointment_id':details.appointment_id,
            'is_accepted':1,
            'timezone': window.localStorage.getItem('timezone') ?? moment.tz.guess(),
            'user_id':this.user.id,
         };
         console.log("== accept details ==",request_data);
         this.$socket.emit('requestActionByTherapist',request_data);
         this.is_active_appointment_id = details.appointment_id;
         this.is_active_user_wait_modal = 1;
         this.is_action_taken_by_search_therapist_modal = 0;
       },
       declineUserRequest(details){
   
         let request_data = {
            'appointment_id':details.appointment_id,
            'is_accepted':0,
            'timezone': window.localStorage.getItem('timezone') ?? moment.tz.guess(),
            'user_id':this.user.id,
         };
         console.log("== accept details ==",request_data);
         this.$socket.emit('requestActionByTherapist',request_data);
         this.is_active_appointment_id = null;
         this.call_initiated_details = null;
         this.is_active_search_therapist_modal = 0;
         this.is_active_user_wait_modal = 0;
         this.is_action_taken_by_search_therapist_modal = 0;
         this.is_action_taken_by_search_therapist_modal = null;
         this.is_active_search_therapist_modal = 0;
         this.is_active_appointment_id = null;
         this.show_search_modal = false;
         this.show_timeout_modal = false;
         this.call_request = false;
         this.call_timer = ref(0);
         this.call_timer_load = false;
         this.pause_call_timer = false;
         this.client_wait_modal.is_wait = 0;
         this.client_wait_modal.current_client_wait_time = 0;
         this.client_wait_modal.total_client_wait_time = 0;
         this.call_request = 0;
       },
       startSessionCall(permission = true){
           console.log("permission",permission);
           if(this.media_stream){
             this.media_stream.getTracks().forEach((track) => {
               track.stop();
             });
           }
           this.getAppointmentDetails(this.search_modal_details.appointment_id,true,"ENABLE_TIMER");
       },
       startSessionCallByApp(status = false,enable_timer = null){
           if(this.media_stream){
             this.media_stream.getTracks().forEach((track) => {
               track.stop();
             });
           }
           navigator.mediaDevices.getUserMedia({video:true, audio: true }).then((media) => {
             this.is_active_user_wait_modal = 0;
             this.client_wait_modal.is_wait = 0;
             this.pause_call_timer = false;
             this.client_wait_modal.current_client_wait_time = 0;
             this.client_wait_modal.total_client_wait_time = 0;
             this.total_client_wait_time = ref(0);
             this.current_client_wait_time = ref(0);
             this.is_action_taken_by_search_therapist_modal = null;
             this.is_active_search_therapist_modal = 0;
             this.is_active_appointment_id = this.search_modal_details.appointment_id;
             this.show_search_modal = false;
             this.show_timeout_modal = false;
             this.display_timeout_color = "none";
             this.display_color = "none";
             this.is_active_user_wait_modal = 0;
             this.total_client_wait_time = ref(0);
             this.current_client_wait_time = ref(0);
             this.display_call_initate_color = "none";
             this.display_call_rejoin_color = "none";
             if(enable_timer){
               this.startAppointmentByTherapist(this.is_active_appointment_id,media);
             }else{
               this.continueAppointmentByTherapist(this.is_active_appointment_id,media);
              console.log("status",status,enable_timer);
             }
            
           }).catch((err) => {
                this.$swal({icon: 'error', title: err,background:'#cb1111',color:'#fff'});
           });
       },
       askTherapistForExtendSession(){
               let ask_extend_appointment = Constant.getbaseurl()+'/therapist/ask-therapist-extended-session';
               let post_data = {
                'status':1,
                'appointment_id':this.search_modal_details.appointment_id,
                'therapist_id':this.user ? this.user.id : null,
                'user_id':this.current_appointment_details.user ? this.current_appointment_details.user.id : null,
                'partner_id':this.current_appointment_details.attendee ? this.current_appointment_details.attendee.user_id : null,
               };
               this.axios.post(ask_extend_appointment,post_data)
               .then((/*{data}*/) => {
                  this.display_call_extend_end_color = "none";
                  this.display_call_extend_wait_color = "block";
               })
               .catch((error) => {
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
               });
       },
       startAppointmentByTherapist(appointment_id,media){
       
         if(appointment_id){
               let update_appointment = Constant.getbaseurl()+'/appointment/start_session_by_therapist';
               let post_data = {
                'appointment_id':appointment_id
               };
               this.axios.post(update_appointment,post_data)
               .then((/*{data}*/) => {
                 this.$mediaStream = media
                 this.media_stream = media;
                 this.connectPeerjs('start_call','enable_timer');
                 
               })
               .catch((error) => {
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
               });
         }
       },
       continueAppointmentByTherapist(appointment_id,media){
         console.log("continue startAppointmentByTherapist",appointment_id);
         if(appointment_id){
                 this.$mediaStream = media
                 this.media_stream = media;
                 this.connectPeerjs('start_call');
         }
       },
       getAppointmentDetails(appointment_id,status = false,enable_timer = null){
             if(appointment_id){
               let appointment_details = Constant.getbaseurl()+'/get-all-session-users-details';
               let post_data = {
                'appointment_id':appointment_id
               };
               this.axios.post(appointment_details,post_data)
               .then(({data}) => {
                   this.call_initiated_details = null;
                   this.current_appointment_details = data.data;
                   if(this.current_appointment_details.attendee && this.current_appointment_details.attendee.user_id){
                     this.partner_id = this.current_appointment_details.attendee.user_id;
                   }
                   if(status){
                     console.log("current_appointment_details new",this.current_appointment_details);
                     this.search_modal_details.appointment_id = appointment_id;
                     this.search_modal_details.user_id = this.current_appointment_details.user.id;
                     this.user_media_stream_status = parseInt(this.current_appointment_details.accepted_appointment.is_video_enabled_by_user) ? false : true;
                     this.partner_media_stream_status = parseInt(this.current_appointment_details.accepted_appointment.is_video_enabled_by_attendee) ? false : true;
   
                     console.log(enable_timer,"ENABLE_TIMER")
                     if(this.current_appointment_details !== undefined && (this.current_appointment_details.accepted_appointment !== undefined) && (this.current_appointment_details.accepted_appointment.actual_start_time)){
                        if(this.$route.name == 'session_call' /*&& *//*(this.$route.params !== undefined && this.$route.params.appointment_id !== undefined)*/){
                        this.startSessionCallByApp(true,enable_timer)
                        }else{
                         this.call_initiated_details = this.current_appointment_details;
                         console.log("already call",this.$route);
                        }
                     }else{
                        this.startSessionCallByApp(true,enable_timer)
                     }
                   }
               })
               .catch((error) => {
                  console.log(error);
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }
               });
             }
       },
       getLiveAppointmentDetails(appointment_id,appointment_meeting_detail){
             if(appointment_id){
               let appointment_details = Constant.getbaseurl()+'/get-all-session-users-details';
               let post_data = {
                'appointment_id':appointment_id
               };
               this.axios.post(appointment_details,post_data)
               .then(({data}) => {
                    let appointment_info = appointment_meeting_detail.appointment;
                   if(appointment_meeting_detail.user_search_timeout && (parseInt(appointment_meeting_detail.total_seconds) < parseInt(appointment_meeting_detail.therapist_search_timer))) {
                     this.current_appointment_details = data.data;
                     if(this.current_appointment_details.attendee && this.current_appointment_details.attendee.user_id){
                       this.partner_id = this.current_appointment_details.attendee.user_id;
                     }
                     this.search_modal_details.appointment_id = appointment_id;
                     this.search_modal_details.user_id = this.current_appointment_details.user.id;
                     this.is_active_user_wait_modal = 1;
                     this.is_active_appointment_id = appointment_id;
                     this.is_active_search_therapist_modal = 0;
                     this.client_wait_modal.current_client_wait_time = 0;
                     this.client_wait_modal.total_client_wait_time = 0;
                     if(this.clientCountDownTimerInt)
                     clearTimeout(this.clientCountDownTimerInt);
                     this.total_client_wait_time = ref(0);
                     this.current_client_wait_time = ref(0);
                     this.client_wait_modal.is_wait = 1;
                     this.clientCountDownTimer();
   
                   }else{
                  //  let current_time = 
                  //  console.log("current_utc_time",moment.utc().format("Y-M-D HH:mm:ss"));
                    if(appointment_info.actual_start_time && !appointment_info.actual_end_time){
                       this.current_appointment_details = data.data;
                       if(this.current_appointment_details.attendee && this.current_appointment_details.attendee.user_id){
                         this.partner_id = this.current_appointment_details.attendee.user_id;
                       }
                       this.search_modal_details.appointment_id = appointment_id;
                       this.search_modal_details.user_id = this.current_appointment_details.user.id;
                       this.is_active_user_wait_modal = 1;
                       this.is_active_appointment_id = appointment_id;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       if(this.clientCountDownTimerInt)
                       clearTimeout(this.clientCountDownTimerInt);
                       this.getAppointmentDetails(appointment_id,true);
                    }else if(appointment_info.appointment_payment_status && appointment_info.appointment_payment_status_time){
                           this.current_appointment_details = data.data;
                           if(this.current_appointment_details.attendee && this.current_appointment_details.attendee.user_id){
                             this.partner_id = this.current_appointment_details.attendee.user_id;
                           }
                           this.search_modal_details.appointment_id = appointment_id;
                           this.search_modal_details.user_id = this.current_appointment_details.user.id;
                           this.is_active_user_wait_modal = 1;
                           this.is_active_appointment_id = appointment_id;
                           this.is_active_search_therapist_modal = 0;
                           this.client_wait_modal.current_client_wait_time = ref(0);
                           this.client_wait_modal.total_client_wait_time = ref(0);
                           if(this.clientCountDownTimerInt)
                           clearTimeout(this.clientCountDownTimerInt);
                           this.is_active_user_wait_modal = 0;
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.total_client_wait_time = ref(0);
                           this.current_client_wait_time = ref(0);
                           this.is_action_taken_by_search_therapist_modal = null;
                           this.is_active_search_therapist_modal = 1;
                           this.is_active_appointment_id = null;
                           this.call_initiated_details = null;
                           this.show_search_modal = false;
                           this.show_timeout_modal = false;
                           this.display_timeout_color = "none";
                           this.display_color = "none";
                           this.total_client_wait_time = ref(0);
                           this.current_client_wait_time = ref(0);
                           this.display_call_rejoin_color = "none";
                           this.display_call_initate_color = "block";
                           this.getAppointmentDetails(this.search_modal_details.appointment_id);
                       
                    }else if(appointment_meeting_detail.total_seconds >= parseInt(appointment_meeting_detail.therapist_search_timer)){
   
                       this.current_appointment_details = data.data;
                       if(this.current_appointment_details.attendee && this.current_appointment_details.attendee.user_id){
                         this.partner_id = this.current_appointment_details.attendee.user_id;
                       }
                       this.search_modal_details.appointment_id = appointment_id;
                       this.search_modal_details.user_id = this.current_appointment_details.user.id;
                       this.is_active_user_wait_modal = 1;
                       this.is_active_appointment_id = appointment_id;
                       this.is_active_search_therapist_modal = 0;
                       this.client_wait_modal.current_client_wait_time = 0;
                       this.client_wait_modal.total_client_wait_time = 0;
                       
                       if(this.clientCountDownTimerInt)
                       clearTimeout(this.clientCountDownTimerInt);
                       this.total_client_wait_time = ref(parseInt(appointment_meeting_detail.payment_screen_timer));
                       this.current_client_wait_time = ref(parseInt(appointment_meeting_detail.user_accept_timeout));
                       this.client_wait_modal.is_wait = 1;
                       this.clientCountDownTimer();
                    }
                   }
               })
               .catch((error) => {
                  if(error.response.status !== undefined &&(error.response.status == 401)){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }
               });
             }
       },
       checkUpcomingRequest(status){
         if(!this.is_active_appointment_id && status){
               //this.load_data = this.load_data + 1;
               let appointment_details = Constant.getbaseurl()+'/therapist/check_upcoming_appointment';
               this.axios.get(appointment_details)
               .then(({data}) => {
                   let response_data = data.data;
                   if(response_data && response_data.upcoming_meeting_request != undefined){
                     if(response_data.upcoming_meeting_request && response_data.upcoming_meeting_request.length){
                       this.upcoming_request_detail = response_data.upcoming_meeting_request[0];
                       if(!this.is_active_appointment_id){
                          this.show_search_modal = false;
                         this.show_timeout_modal = false;
                         this.show_reason_modal = false;
                         this.pause_call_timer = false;
                         this.display_color = "none";
                         this.call_request = false;
                         this.display_chat_color = "none";
                         this.display_timeout_color = "none";
                         this.display_reason_color = "none";
                         this.display_reason_color = "none";
                         this.display_call_initate_color = "none";
                         this.display_call_rejoin_color = "none";
                         this.is_mute = false;
                         this.is_blur = false;
                         this.is_support = false;
                         this.call_request = false;
                         this.is_chat = false;
                         this.message_count = 0;
                         this.call_timer = 0;
                         this.call_timer_load = false;
                         this.extend_call_status = 0;
                         this.extend_call_ask_status = 0;
                         this.is_active_user_wait_modal = 0;
                         this.client_wait_modal.is_wait = 0;
                         this.client_wait_modal.current_client_wait_time = 0;
                         this.client_wait_modal.total_client_wait_time = 0;
                         this.total_client_wait_time = ref(0);
                         this.current_client_wait_time = ref(0);
                         this.is_action_taken_by_search_therapist_modal = null;
                         this.is_active_search_therapist_modal = 0;
                         this.is_active_appointment_id = null;
                         this.call_initiated_details = null;
                         this.show_search_modal = false;
                         this.show_timeout_modal = true;
                         this.display_call_end_color = "none";
                         this.display_decline_reason_color = "none";
                         this.display_call_extend_end_color = "none";
                         this.display_call_extend_wait_color = "none";
                         this.$mediaStream = null;
                         this.$mediaUserRemoteStream = null;
                         this.$mediaPartnerRemoteStream = null;
                         //this.media_stream = null;
                         this.user_media_stream = null;
                         this.partner_media_stream = null;
                         this.search_modal_details = [];
                         this.chat_messages = [];
                         this.is_chat = false;
                         this.current_appointment_details = null;
                         this.display_timeout_color = "none";
                         this.display_color = "none";
                         this.display_upcoming_color = "block";
                         this.upcoming_modal_details = this.upcoming_request_detail;
                         if(this.upcoming_modal_details){
                          this.upcoming_modal_details.load_status = status;
                         }
                       }else{
                        // this.display_upcoming_color = "block";
                         //this.upcoming_modal_details = this.upcoming_request_detail;
                         //this.upcoming_modal_details.load_status = status;
                       }
                      console.log("upcoming appointment data",this.upcoming_request_detail);
                     }else{
                      // this.upcoming_modal_details.load_status = false;
                     }
                   }else{
                     //this.upcoming_modal_details.load_status = false;
                   }
               })
               .catch((error) => {
                 console.log(error);
                  if(error.response.status !== undefined && (error.response.status == 401)){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }
               });
             }
       },
       sendMessage(data){
   
         let today = new Date();
         let post_data = data;
             post_data.sender_name = this.user.name;
             post_data.senderId = this.user.id;
            
             if(this.current_appointment_details && (this.current_appointment_details.therapist !== undefined)){
               post_data.receiverId = this.current_appointment_details.therapist.id;
               post_data.image_url = this.current_appointment_details.therapist.profile_image;
               let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
               let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
               let dateTime = date +''+ time;
               post_data.request_id = dateTime;
               if(this && this.$socket){
                this.$socket.emit('sendMessage',post_data);
               }
             }
             if(this.current_appointment_details && (this.current_appointment_details.user !== undefined)){
               post_data.receiverId = this.current_appointment_details.user.id;
               post_data.image_url = this.current_appointment_details.user.profile_image;
               let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
               let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
               let dateTime = date +''+ time;
               post_data.request_id = dateTime;
               if(this && this.$socket){
                this.$socket.emit('sendMessage',post_data);
               }
             } 
             if(this.current_appointment_details && (this.current_appointment_details.attendee !== undefined)){
               if(this.current_appointment_details.attendee){
                 post_data.receiverId = this.current_appointment_details.attendee.user_id;
                 post_data.image_url = null;
                 let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
                 let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                 let dateTime = date +''+ time;
                 post_data.request_id = dateTime;
                 if(this && this.$socket){
                  this.$socket.emit('sendMessage',post_data);
                 }
               }
             }
       },
       getAvailabilty(){
            let get_availabilty = Constant.getbaseurl()+'/therapist/check/available';
              this.axios.get(get_availabilty)
              .then(({data}) => {
                console.log("available_status",data);
                   let response_data = data.data;
                   this.is_available_now = response_data.user.is_available_now;
                   this.$isAvailable = response_data.user.is_available_now;
              })
              .catch((error) => {
                  if(error.response.status == 401){
                      //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
   
      },
      upcomingScheduleRequestAction(status){
         let upcoming_request_action = Constant.getbaseurl()+'/therapist/get-therapist-response-upcoming-session';
         let post_data = {};
             post_data.appointment_id = this.upcoming_modal_details.id;
             post_data.status = status;
             post_data.user_id = this.upcoming_modal_details.user.id;
              this.axios.post(upcoming_request_action,post_data)
              .then((/*{data}*/) => {
                  // let response_data = data.data;
                   this.load_data = this.load_data + 1;
                   this.$swal({icon: 'success', title: (status ? "Session request accepted successfully" : "Session request rejected successfully")});
                   this.checkUpcomingRequest(true);
              })
              .catch((error) => {
                  if(error.response.status == 401){
                      this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
      },
      checkOngoingCallForLiveCall(){
       let ongoing_call = Constant.getbaseurl()+'/therapist/my_profile';
              this.axios.get(ongoing_call)
              .then(({data}) => {
                   let response_data = data.data;
                   let appointment_meeting_detail = response_data ? response_data.ongoing_meeting_request : null;
                   if(appointment_meeting_detail){
                         if(appointment_meeting_detail.appointment){
                           this.getLiveAppointmentDetails(appointment_meeting_detail.appointment.appointment_id,appointment_meeting_detail);
                         }
                   }
              })
              .catch((error) => {
                  if(error.response === undefined){
                    console.log("error new",error);
                  }else if(error.response.status == 401){
                      Auth.logout();
                      this.$router.push('login');
                  }
              });
      },
      checkOngoingCall(enable_timer = null){
             let ongoing_call = Constant.getbaseurl()+'/therapist/check_ongoing_call';
              this.axios.post(ongoing_call,{'modifiedNew':1})
              .then(({data}) => {
                   let response_data = data.data;
                   this.ongoing_call_details = response_data;
                   if(this.ongoing_call_details && (this.ongoing_call_details.total_call_seconds <= this.ongoing_call_details.total_call_time)){
                       this.ask_extend_timer = this.ongoing_call_details.appointment_detail.extend_session_ask_request_timer;
                       if(enable_timer){
                         let enable_call_data = {};
                         enable_call_data.customer_id = this.current_appointment_details.user ? this.current_appointment_details.user.id : null;
                         enable_call_data.user_id = this.current_appointment_details.user ? this.current_appointment_details.user.id : null;
                         enable_call_data.therapist_id = this.current_appointment_details.therapist.id;
                         enable_call_data.appointment_id = this.is_active_appointment_id;
                         enable_call_data.attendee_id = this.current_appointment_details.attendee ? this.current_appointment_details.attendee.user_id:null;
                         enable_call_data.is_extended = 0;
                         this.$socket.emit('enableCallTimer',enable_call_data);
                       }
                       if(this.client_call_interval_timeout){
                        clearTimeout(this.client_call_interval_timeout);
                       }
                       this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                       this.getCallDeclineReasons();
                       this.$router.push({
                         name: 'session_call', 
                         params: {"appointment_id": this.is_active_appointment_id }
                       });
                      this.countDownCallTimer();
                   }
              })
              .catch((error) => {
                  console.log(error);
                  if(error.response.status == 401){
                      //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
      },
      checkOnlineCallTimer(){
          let online_data = {};
          if(this.current_appointment_details){
            online_data.appointment_id = this.current_appointment_details.id;
            online_data.user_id = this.user ? this.user.id : null;
            online_data.user_type = 'THERAPIST';
           this.$socket.emit('check_ongoing_appointment_session',online_data)
          }
      },
      syncOngoingCallTimer(){
              this.call_timer_load = true;
              let ongoing_call = Constant.getbaseurl()+'/therapist/check_ongoing_call';
              this.axios.post(ongoing_call,{'modifiedNew':1})
              .then(({data}) => {
                   let response_data = data.data;
                   this.ongoing_call_details = response_data;
                   if(this.ongoing_call_details && this.ongoing_call_details.total_call_seconds){
                     this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                     this.call_timer_load = false;
                     this.ask_extend_timer = this.ongoing_call_details.appointment_detail.extend_session_ask_request_timer;
                     this.pause_call_timer = false;
                   }
              })
              .catch((error) => {
                  if(error.response.status == 401){
                      //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
      },
      getOngoingCallInfo(){
             let ongoing_call = Constant.getbaseurl()+'/therapist/check_ongoing_call';
              this.axios.post(ongoing_call,{'modifiedNew':1})
              .then(({data}) => {
                   let response_data = data.data;
                   this.ongoing_call_details = response_data;
                   console.log('ongoing_call_details',this.ongoing_call_details);
                   this.ask_extend_timer = this.ongoing_call_details.appointment_detail.extend_session_ask_request_timer;
                   let enable_call_data = {};
                       enable_call_data.customer_id = this.current_appointment_details.user ? this.current_appointment_details.user.id : null;
                       enable_call_data.therapist_id = this.user_id;
                       enable_call_data.appointment_id = this.is_active_appointment_id;
                       enable_call_data.attendee_id = this.current_appointment_details.attendee ? this.current_appointment_details.attendee.user_id:null;
                       enable_call_data.is_extended = this.extend_call_status;
                       enable_call_data.is_extended = this.extend_call_ask_status;
   
                   if(this.ongoing_call_details && this.ongoing_call_details.total_call_seconds){
   
                     this.pause_call_timer = false;
                     this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                     this.$socket.emit('enableCallTimer',enable_call_data)
                     //this.countDownCallTimer();
                   }
              })
              .catch((error) => {
                  if(error.response.status == 401){
                      //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
      },
      getCallDeclineReasons(){
             let ongoing_call_decline_reasons = Constant.getbaseurl()+'/decline/reasons';
              this.axios.get(ongoing_call_decline_reasons,{ params: {flow_type:"CALL_END",role: 'THERAPIST'}})
              .then(({data}) => {
                   let response_data = data.data;
                   this.decline_reasons = response_data;
                   console.log("decline_reasons",this.decline_reasons);
              })
              .catch((error) => {
                  if(error.response.status == 401){
                      Auth.logout();
                      this.$router.push('login');
                  }else{
                      //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  }
              });
      },
      updateExtendedDetails(appointment_id,type = "therapist_ask_extended",status = 1){
        let save_extended_session = Constant.getbaseurl()+'/therapist/save_extended_session_status';
        let post_data = null;
        if(type == 'therapist_ask_extended'){
          post_data = {'therapist_ask_extended':status,'appointment_id':appointment_id};
        }
        if(type == 'therapist_ask_status'){
          post_data = {'therapist_ask_status':status,'appointment_id':appointment_id};
        }
        if(type == 'appointment_extend_payment_status'){
          post_data = {'appointment_extend_payment_status':status,'appointment_id':appointment_id};
        }
        this.axios.post(save_extended_session,post_data)
        .then(() => {
             this.extend_call_ask_status = 1;
        })
        .catch((error) => {
            if(error.response.status == 401){
                Auth.logout();
                this.$router.push('login');
            }else{
               console.log("error",error);
            }
        });
      },
      getExtendSessionDetails(appointment_id){
       let check_extended_session = Constant.getbaseurl()+'/therapist/check_extended_session_status';
        this.axios.get(check_extended_session,{ params: {'appointment_id':appointment_id}})
        .then(({data}) => {
             let response_data = data.data;
               this.extend_call_ask_status = 1;
             if(response_data){
   
               this.is_extended = response_data.is_extended;
               if(!response_data.therapist_ask_extended_time && !response_data.therapist_ask_extended && (!response_data.therapist_ask_status_time)){
                     this.pause_call_time = false;
                     this.pause_call_time = this.call_timer;
                     this.display_call_extend_end_color = "block";
                     this.display_call_extend_wait_color = "none";
                     if(!this.extend_call_ask_status){
                      this.updateExtendedDetails(appointment_id);
                     }
               }else if(response_data.therapist_ask_extended_time && (!response_data.therapist_ask_status_time)){
                     this.pause_call_time = false;
                     this.extend_call_ask_status = 1;
                     this.pause_call_time = this.call_timer;
                     this.display_call_extend_end_color = "block";
                     this.display_call_extend_wait_color = "none";
                    // this.updateExtendedDetails(appointment_id,'therapist_ask_status',3);
               } else if(response_data.therapist_ask_extended_time && (response_data.therapist_ask_status_time && (response_data.therapist_ask_status == '0'))){
                         this.client_wait_modal.is_wait = 0;
                         this.client_wait_modal.current_client_wait_time = 0;
                         this.client_wait_modal.total_client_wait_time = 0;
                         this.display_call_extend_wait_color = 'none';
                         this.display_call_extend_end_color = 'none';
                         this.show_search_modal = false;
                         this.display_timeout_color = "none";
                         this.extend_call_ask_status = 1;
                         this.display_color = "none";
                         this.checkOnlineCallTimer();
               }else if(response_data.therapist_ask_extended_time && (response_data.therapist_ask_status_time && response_data.therapist_ask_status && (!response_data.appointment_extend_payment_status))){
                        
                         let current_utc_date = moment.utc().format("Y-M-D HH:mm:ss");
                         let total_time = moment(current_utc_date,'YYYY-MM-DD HH:mm:ss').diff(moment(response_data.therapist_ask_status_time,'YYYY-MM-DD HH:mm:ss'), 'seconds');
                         if((total_time > response_data.extend_session_request_timer) && (response_data.user_ask_status == 2)){
                           this.is_active_user_wait_modal = 1;
                           this.extend_call_status = 0;
                           this.extend_call_ask_status = 1;
                           this.is_active_search_therapist_modal = 0;
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.display_call_extend_wait_color = 'none';
                           this.display_call_extend_end_color = 'none';
                           this.show_search_modal = false;
                           this.show_timeout_modal = true;
                           this.display_timeout_color = "block";
                           this.display_color = "none";
                           this.message = "Due to delay in payment, we are decling the extend request.Please continue with session";
                           this.updateExtendedDetails(this.is_active_appointment_id,'appointment_extend_payment_status',3);
                           this.checkOnlineCallTimer();
                         }
                         
                         if((total_time < response_data.extend_session_request_timer) && (response_data.user_ask_status == 1)){
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.display_call_extend_wait_color = 'none';
                           this.display_call_extend_end_color = 'none';
                           this.show_search_modal = false;
                           this.show_timeout_modal = true;
                           this.display_timeout_color = "block";
                           this.display_color = "none";
                           this.pause_call_timer = true;
                           this.extend_call_ask_status = 1;
                           this.message = this.current_appointment_details.user.name + " has accepted the extend session request and making payment.Please wait till payment completes.";
                         }
                         if((total_time < response_data.extend_session_request_timer) && (response_data.user_ask_status == 0)){
                           
                           this.client_wait_modal.is_wait = 0;
                           this.client_wait_modal.current_client_wait_time = 0;
                           this.client_wait_modal.total_client_wait_time = 0;
                           this.display_call_extend_wait_color = 'none';
                           this.display_call_extend_end_color = 'none';
                           this.show_search_modal = false;
                           this.show_timeout_modal = true;
                           this.display_timeout_color = "block";
                           this.display_color = "none";
                           this.message = this.current_appointment_details.user.name + " has declined the extend session request.";
                           this.checkOnlineCallTimer();
                         }
               }else if(response_data.therapist_ask_extended_time && (response_data.therapist_ask_status_time && response_data.therapist_ask_status && (response_data.appointment_extend_payment_status))){
                //  console.log("dsadaddasd","extend call time");
                         this.client_wait_modal.is_wait = 0;
                         this.client_wait_modal.current_client_wait_time = 0;
                         this.client_wait_modal.total_client_wait_time = 0;
                         this.display_call_extend_wait_color = 'none';
                         this.display_call_extend_end_color = 'none';
                         this.show_search_modal = false;
                         this.extend_call_ask_status = 1;
               }
               this.extend_call_status = response_data.is_extended;
             }
        })
        .catch((error) => {
          
            if(error.response.status == 401){
                Auth.logout();
                this.$router.push('login');
            }else{
                //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
            }
        });
      },
      countDownTimer(){
           if (this.percent < this.max_timeout) {
               setTimeout(() => {
                   this.percent += 1;
                   this.current_percent -= 1;
                  // this.max_timeout = this.max_timeout;
                   this.countDownTimer()
               }, 1000);
           }
       },
       countDownCallTimer(pause_time = false){
             
             if (this.call_timer >= 0) {
               if(this.client_call_interval_timeout){
                   clearTimeout(this.client_call_interval_timeout);
                   this.client_call_interval_timeout = null;
               }
               this.client_call_interval_timeout = setTimeout(() => {
                     if((this.call_timer < this.ask_extend_timer) && (!this.extend_call_status) && (this.is_active_appointment_id)){
                         if(!this.extend_call_ask_status)
                         this.getExtendSessionDetails(this.is_active_appointment_id);
                     }
                     if(pause_time){
                       this.call_timer -= 1;
                     }
                     if(this.call_timer){
                       this.checkOnlineCallTimer();
                       this.countDownCallTimer();
                     }
               },1000);
   
             }
       },
       clientCountDownTimer(){
           if(this.clientCountDownTimerInt){
             clearTimeout(this.clientCountDownTimerInt);
           }
            // console.log("this.client_wait_moda;",this.client_wait_modal);
           if (this.current_client_wait_time < this.total_client_wait_time) {
              this.clientCountDownTimerInt = setTimeout(() => {
                   this.current_client_wait_time += 1;
                   this.client_wait_modal.current_client_wait_time = ref(this.current_client_wait_time);
                   this.client_wait_modal.total_client_wait_time = ref(this.total_client_wait_time);
                   this.clientCountDownTimer()
               }, 1000);
           }
       },
     }
   }
   
</script>
<style scoped>
   @import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
   @import "https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap";
   @import "assets/css/owl.carousel.min.css";
   @import "assets/css/animate.css";
   @import "assets/css/style.css";
   @import "assets/css/responsive.css";
</style>