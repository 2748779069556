<template>
    <div class="left-bar">
        <div class="nav-bar">
          <ul>
            <li :class="[route.name == 'home' ? 'active' : '']" ><a href ="" @click.prevent="loadPage('home')"><img src="../assets/images/home-ic.svg">Home</a></li>
            <li :class="[route.name == 'sessions' ? 'active' : '']"><a href ="" @click.prevent="loadPage('sessions')"><img src="../assets/images/session.svg">Sessions</a></li>
            <li :class="[(route.name == 'events'||route.name == 'event_detail') ? 'active' : '']"><a href ="" @click.prevent="loadPage('events')"><img src="../assets/images/session.svg">Events</a></li>
            <li :class="[route.name == 'articles' ? 'active' : '']"><a href ="" @click.prevent="loadPage('articles')"><img src="../assets/images/artist.svg">Articles</a></li>
            <li :class="[route.name == 'my_schedules' ? 'active' : '']"> <a href ="" @click.prevent="loadPage('my_schedules')"><img src="../assets/images/wallet.svg">My Schedules</a></li>
            <li :class="[route.name == 'revenue' ? 'active' : '']"><a href ="" @click.prevent="loadPage('revenue')"><img src="../assets/images/payment.svg">Revenue</a></li>
            <li :class="[route.name == 'payouts' ? 'active' : '']"><a href ="" @click.prevent="loadPage('payouts')"><img src="../assets/images/gift.svg">Payouts</a></li>
            <li :class="[route.name == 'notifications' ? 'active' : '']"><a href ="" @click.prevent="loadPage('notifications')"><img src="../assets/images/notification.svg">Notifications</a></li>
            <li :class="[route.name == 'my_performance' ? 'active' : '']"><a href ="" @click.prevent="loadPage('my_performance')"><img src="../assets/images/fund.svg">My Performance</a></li>
            <li :class="[route.name == 'faq' ? 'active' : '']"><a href ="" @click.prevent="loadPage('faq')"><img src="../assets/images/faq.svg">FAQ</a></li>
            <li :class="[route.name == 'resources' ? 'active' : '']"><a href ="" @click.prevent="loadPage('resources')"><img src="../assets/images/artist.svg">Resources</a></li>
            <li :class="[route.name == 'terms_and_services' ? 'active' : '']"><a href ="" @click.prevent="loadPage('termservices')"><img src="../assets/images/service.svg">Terms Of Services</a></li>
            <li :class="[route.name == 'privacy' ? 'active' : '']"><a href ="" @click.prevent="loadPage('privacy')"><img src="../assets/images/privacy.svg">Privacy Policy</a></li>
            <li :class="[route.name == 'aboutus' ? 'active' : '']"><a href ="" @click.prevent="loadPage('aboutus')"><img src="../assets/images/about.svg">About Us</a></li>
            <li :class="[route.name == 'my_profile' ? 'active' : '']"><a href ="" @click.prevent="loadPage('my_profile')" ><img src="../assets/images/set.svg">My Profile</a></li>
          </ul>
        </div>
      <UserOfflineModalPage v-if="show_modal" @close = "closeModal"/>
    </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import UserOfflineModalPage from '@/components/UserOfflineModalPage.vue'

export default {
  props:['available_status','current_loaded_panel'],
  components: {
    UserOfflineModalPage
  },
  data(){
    return {
          user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
          name : "",
          available_load:true,
          is_available_now:1,
          show_modal:0,
          intial_state :0,
          date : new Date().toJSON().slice(0,10).replace(/-/g,'-'),
    };
  },
  setup() {
    const route  = useRoute();
    const router = useRouter();
    onMounted(async () => {
       await router.isReady();
    });
    window.localStorage.setItem('route', route.path);
    return { route }
  },
  mounted(){
       this.user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
       this.name = this.user ? this.user.name : null;
       this.is_available_now = this.available_status;
      // this.getAvailabilty();
       this.intial_state = 1;
       console.log("new user details",this.user);
  },
  methods:{
    
    loadPage(path){
      this.$router.push({'name':path});
    },
    /*getAvailabilty(){
         let get_availabilty = Constant.getbaseurl()+'/therapist/check/available';
           this.axios.get(get_availabilty)
           .then(({data}) => {
                let response_data = data.data;
                this.name = response_data.user.name;
                if(this.available_load){
                  this.is_available_now = response_data.user.is_available_now;
                  this.intial_state = this.is_available_now;
                  this.$isAvailable = response_data.user.is_available_now;
                }
           })
           .catch((error) => {
              this.is_available_now = this.intial_state;
               if(error.response.status == 401){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });

   },*/
   makeAvailable(){
        let get_availabilty = Constant.getbaseurl()+'/availability/delete_unavailability';
           this.axios.post(get_availabilty,{'date':this.date})
           .then(() => {
                //this.getAvailabilty();
                this.$swal({icon: 'success', title: "Your availability updated successfully"});
                this.$router.push('/edit_availabilty');
           })
           .catch((error) => {
               if(error.response.status == 401){
                   this.show_modal = 0;
                   this.is_available_now = this.intial_state;
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.show_modal = 0;
                   this.is_available_now = this.intial_state;
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });
   },
   getToProfile(){
      this.$router.push('edit_profile');
   },
   changeAvailabilty(){
      if(!this.is_available_now){
        this.available_load = false;
        this.getAvailabilty();
        this.show_modal = 1;
      }
      if(this.is_available_now){
        this.makeAvailable();
      }
   },
   closeModal(data){
    if(data.closer == 1){
        this.show_modal = 0;

    }
    if(data.load){
        this.$router.push('edit_availabilty');
    }else{
      this.is_available_now = this.intial_state;
    }
   }
  },
}
</script>


