<template>
<div class="modal modal-width" id ="search_request_modal" :style="{'background-color':background_color,'display':display_call_end_color}">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer wow fadeInUp animate">
              <div class="session-timer-inner meat-dr">
                  <img src="../assets/images/thumb.svg" alt="">
                  <h4>Session Completed.</h4>
                  <p>{{end_message}}</p>
                  <div class="session-btn">
                       <a href="#" class="cm-btn white-btn" @click.prevent="hideCallEndModal"><span>No</span></a>
                        <a href="#" class="cm-btn" @click.prevent="hideCallEndModal"><span>Yes</span></a>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
export default {
  props:['show_timeout_modal','display_call_end_color','end_message'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
             //  display_color:"none",
           };
  },
  mounted(){

  },
  methods:{
    hideCallEndModal(){
         this.$emit('closeCallEnd',{'closer':1,'load':0});
    },
  }
}
</script>