<template>
 <Transition name="slide-fade">
  <div class="shadow-block bg-green d-flex align-items-center main_item_class" :style="'height:'+current_height" v-if="show_call_info" @click.prevent="goToAppointmentSession">
     <div class="wait-circle">
        <div class="container-custom">
             <div class="box">
               <div class="chart">
                <radial-progress-bar :diameter="90" :innerStrokeColor ="innercolor" :completed-steps="current_call_time" :total-steps="total_call_time" :startColor="startcolor" :stopColor="stopcolor" :strokeWidth="strokewidth">
                <span style="font-size:17px;">{{timeFromSecs(this.total_call_time - this.current_call_time)}}<span></span></span>
               </radial-progress-bar>
             </div>
          </div>
        </div>
     </div>
     <h2 class="waiting-s" style="padding:30px;font-size:21px;">You have ongoing appointment call with {{call_details.user.name ?? 'user'}}.</h2>
 </div>
</Transition>
</template>
<script>
import moment from "moment-timezone";
import { ref } from "vue";
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import RadialProgressBar from "vue3-radial-progress";
export default {
  props:['height','call_details'],
  emits:['moveToCallDetails'],
  components: {
    RadialProgressBar
  },
  data() {
      return {
            user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
            innercolor:"#00000000",
            startcolor:"#f9fafb",
            stopcolor:"#f9fafb",
            strokewidth:6,
            current_height:'22%',
            total_call_time:0,
            current_call_time:0,
            utc_date:null,
            call_time:null,
            show_call_info:null,
            clientCountDownTimerInt:null,
         };
  },
  mounted(){
    this.current_height = this.height+"%";
    this.utc_date = moment.utc().format("Y-M-D HH:mm:ss");
    this.call_time =  moment(this.call_details.accepted_appointment.call_start_time,"Y-M-D HH:mm:ss").format('Y-M-D HH:mm:ss');
    let ms = moment(this.utc_date,"Y-M-D HH:mm:ss").diff(moment(this.call_time,"Y-M-D HH:mm:ss"));
    let call_duration = moment.duration(moment(this.call_details.accepted_appointment.session_time,"hh:mm").format("hh:mm")).minutes();
    let sec_duration = moment.duration(ms).seconds();
    let min_duration = moment.duration(ms).minutes();
    let hur_duration = moment.duration(ms).hours();
    let final_duration = 0;
    if(hur_duration){
       final_duration = (hur_duration * 60 * 60);
    }
    if(min_duration){
       final_duration += (min_duration * 60);
    }
    if(sec_duration){
       final_duration += (sec_duration);
    }
        console.log("this.client_wait_moda;",hur_duration,min_duration,sec_duration,final_duration);
    if(final_duration <= (call_duration * 60)){
      this.show_call_info = 1
      this.total_call_time = ref(call_duration * 60);
      this.current_call_time = ref(final_duration);
      this.clientCountDownTimer();
    }else{
      this.total_call_time = ref(0);
      this.current_call_time = ref(0);
      this.show_call_info = 0;
      this.endCompletecall();
    }
  },
  methods:{
    timeFromSecs(seconds_data)
    {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            var time    = minutes+':'+seconds;
           // var time    = hours::minutes+':'+seconds;
            return time;
    },
    clientCountDownTimer(){
        if(this.clientCountDownTimerInt){
          clearTimeout(this.clientCountDownTimerInt);
        }
        if (this.current_call_time <= this.total_call_time) {
           this.clientCountDownTimerInt = setTimeout(() => {
                this.current_call_time += 1;
                this.current_call_time = ref(this.current_call_time);
                this.total_call_time = ref(this.total_call_time);
                this.clientCountDownTimer()
            }, 1000);
        }
    },
    endCompletecall(){
            let reason = null;
            let post_data = {};
            post_data.appointment_id = this.call_details.id;
            post_data.is_decline = 0;
            post_data.reason = reason;
            let call_end_url = Constant.getbaseurl()+'/end-call';
            this.axios.post(call_end_url,post_data)
              .then((/*{data}*/) => {
                  this.$swal({icon: 'success', title: "Your ongoing appointement ended successfully."});
                 // let response_data = data.data;
                //  this.$router.push('/');
              })
              .catch((error) => {
                 if(error.response.status == 401){
                     Auth.logout();
                     this.$router.push('login');
                 }else if(error.response.status == 501){
                     this.$swal({icon: 'success', title: "Call Ended Successfully"});
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
              });
    },
    goToAppointmentSession(){
      this.$emit('moveToCallDetails',this.call_details);
    }
  }
} 
</script>
<style scoped>
 .main_item_class{
   margin-bottom: 10px;
   border-radius: 14px;
   cursor:pointer;
   background:#69707a !important
 }
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.loader_client {
  color: #ffffff;
  font-size: 30px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  margin: 20px;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
.wait-circle {
    margin-top: -1%;
}
@webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>


