import { reactive } from 'vue';

export const Store = reactive({
  show_offline_modal: 1,
  showModal() {
    this.show_offline_modal = 1;
    console.log("show modal");
  },
  hideModal() {
    this.show_offline_modal = 0;
  },
})