<template>
   <div class="col-md-8">
      <UserCallWaitInfo style="margin-bottom:10px;" :height="16" :call_details="call_initiated_details" v-if="call_initiated_details && call_initiated_details.accepted_appointment && !call_initiated_details.accepted_appointment.actual_end_time" @move-to-call-details="moveToCallDetails"/>
      <ClientWaitMessage :client_wait="client_wait"/>
      <div class="right-bar-left">
         <Transition name="slide-fade">
            <div class="shadow-block" v-if ="!intial_loaded">
               <h2>Hello<span>{{user ? user.name+',' : ','}}</span></h2>
            </div>
         </Transition>
         <h4> <img src="../assets/images/board.svg">My Schedule <a href="/my_schedules" v-show="total_session">View All</a></h4>
         <div v-show ="session_list.length">
            <div class="shedule-time" v-for="session in session_list" :key="session.id">
               <div class="row">
                  <div class="col-md-6">
                     <div class="shedule-time-left">
                        <ul>
                           <li>{{formatDate(session.user_datetime)}}<span>Date</span></li>
                           <li>{{formatTime(session.user_start_datetime)}}<span>Time</span></li>
                           <li>{{diffTime(session.user_start_datetime,session.user_end_datetime)}}<span>Duration</span></li>
                        </ul>
                        <h6>{{ session.user_profile.name}}</h6>
                        <p>Already had {{ session.already_sessions }} sessions</p>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="shedule-time-right">
                        <ul>
                           <li>
                              <span>Gender</span>
                              {{ session.user_profile.profile.gender}}
                           </li>
                           <li>
                              <span>Symptoms</span>
                              {{session.categories_data}}
                           </li>
                        </ul>
                        <p>Notes</p>
                        <h6>{{session.user_notes}}</h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div v-show ="!session_list.length">
            <div class="shedule-time" v-if = "!is_loaded">
               <div class="row">
                  <h6 style="text-align: center;">No Schedules Available...</h6>
               </div>
            </div>
            <template v-if="is_loaded">
               <div class="nb-spinner" ></div>
            </template>
         </div>
         <template v-if="event_data">
            <h4> <img src="../assets/images/board.svg">Upcoming Events <a href="/events" v-show="total_session">View All</a></h4>
                 <div v-show ="event_data.length">
                    <div class="shedule-time" v-for="event in event_data" :key="event.id">
                       <div class="row">
                          <div class="col-md-6">
                             <div class="shedule-time-left">
                                <ul>
                                  <template v-if ="event.event_dates.length">
                                    <li>{{formatDate(event.event_dates[0].event_start_datetime)}}<span>Date</span></li>
                                    <li>{{formatTime(event.event_dates[0].event_start_datetime)}}<span>Time</span></li>
                                    <li>{{diffTime(event.event_dates[0].event_start_datetime,event.event_dates[0].event_end_datetime)}}<span>Duration</span></li>
                                  </template>
                                </ul>
                                <h6>{{ event.event_name }}</h6>
                                <p>Max. {{ event.event_max_attendee }} Attendee</p>
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="shedule-time-right">
                                <ul>
                                   <li>
                                      <span>Event Type</span>
                                      {{ event.type}}
                                   </li>
                                   <li>
                                      <template v-if ="event.event_categories.length">
                                           <span>Event Categories</span>
                                           {{ event.event_categories[0].name}}
                                      </template>
                                   </li>
                                   <li>
                                      <template v-if ="event.event_tags.length">
                                           <span>Event Tags</span>
                                           {{ event.event_tags[0].name}}
                                      </template>
                                   </li>
                                </ul>
                                <p>Event Payment</p>
                                <h6>{{event.payment}}</h6>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
         </template>
      </div>
   </div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import moment from "moment-timezone";
import ClientWaitMessage from "./ClientWaitMessage.vue";
import UserCallWaitInfo from "./UserCallWaitInfo.vue";
export default {
  props:['client_wait','intial_loaded','call_initiated_details'],
  emits:['moveToCallDetails'],
  components:{
   ClientWaitMessage,UserCallWaitInfo
  },
  data() {
        return {
              user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
              total_session : 0,
              name_display:1,
              event_data:null,
              session_list : [],
              tips_list:[],
              is_loaded:true,
           };
  },
  mounted(){

      this.getSchedules();
      //this.getTipsList();
      this.getUpcomingEventList();
      setTimeout(() => {
 
               this.name_display = 0;
            }, 2000);
  },
  methods: {
      getTipsList(){
          let getTipsList = Constant.getbaseurl()+'/tips';
          this.axios.get(getTipsList)
            .then(({data}) => {
                let response_data = data.data;
                this.tips_list = response_data ? response_data.tips : [];
            })
            .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                }
            });
     },
     getSchedules(){

             this.is_loaded = true;
             let my_schedules = Constant.getbaseurl()+'/therapist/web/my-schedules';
             this.axios.post(my_schedules,{"per_page":2})
                .then(({data}) => {
                  let response_data = data.data;
                  if(response_data.total != undefined){
                     this.total_session = response_data.total;
                  }
                  if(response_data.sessions != undefined && response_data.total > 0){
                     let session_data = response_data.sessions;
                       this.session_list = session_data;
                     //  console.log("sessions",this.session_list);
                  }
                  this.is_loaded = false;
                })
                .catch((error) => {
                   console.log(error.response.status);
                   if(error.response.status == 401){
                       this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                       Auth.logout();
                       this.$router.push('login');
                   }else{
                       this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                   }
                });
      
   },
   getUpcomingEventList(){

          let pagination_ref = "";
          pagination_ref = "?pageoffset=2&pageno=1";
          let getUpcomingEventsList = Constant.getbaseurl()+'/events'+pagination_ref;
          this.axios.get(getUpcomingEventsList)
            .then(({data}) => {
                this.is_loaded = false;
                let response_data = data;
                this.event_data = response_data.data;
          })
          .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
          });
   },
   formatDate(dateString) {
      return moment(dateString,"Y-M-D HH:mm:ss").format('D MMM YYYY');
   },
   formatTime(dateString) {
      return moment(dateString,"Y-M-D HH:mm:ss").format('h:mm A');
   },
   diffTime(fromString,toString) {
      var a = moment(fromString);//now
      var b = moment(toString);
      return b.diff(a, 'minutes')+" Minutes";
   },
   moveToCallDetails(details){
     this.$emit('moveToCallDetails',details);
   }       
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>


