<template>
<div class="modal modal-width" id ="decline_extend_modal" :style="{'background-color':background_color,'display':display_call_extend_end_color}">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
                <img src="../assets/images/timer.png" alt="" class="mx-auto d-block mb-3">
                <h4 class="mb-4">Do you want to extend the<br> session ?</h4>
                <div class="session-btn">
                     <a href="#" class="cm-btn white-btn" @click.prevent="hideAskModal"><span>No</span></a>
                      <a href="#" class="cm-btn" @click.prevent="showAskModal"><span>Yes</span></a>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
export default {
  props:['display_call_extend_end_color','current_appointment_details'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               current_reason:null,
               selected_reason:null,
               ask_modal:true,
               extend_modal:false,
           };
  },
  mounted(){

  },
  methods:{
    hideAskModal(){
         this.$emit('closeCallExtend',{'closer':1,'load':0});
    },
    showAskModal(){
       this.$emit('askCallExtend',true);
    }

  }
}
</script>


