<template>
<div class="modal modal-width" id ="search_request_modal" :style="{'background-color':background_color,'display':display_timeout_color}">
    <div class="modal-dialog">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body">
            <div class="session-timer-inner">
            <img src="../assets/images/timer.png" alt="" class="mx-auto d-block mb-3">
            <h4 class="offline-t" style="text-align: center;">{{message}}</h4>
                <div class="session-btn mt-5">
                <a href="#" class="cm-btn decline-btn" @click.prevent="hideOfflineModal()"><span>Close</span></a>
                <a href="#" class="cm-btn" @click.prevent="hideOfflineModal()"><span>Ok</span></a>
                </div>
            </div>
        </div>

      </div>
    </div>
</div>
</template>
<script>
export default {
  props:['show_timeout_modal','display_timeout_color','message'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
             //  display_color:"none",
           };
  },
  mounted(){

  },
  methods:{
    hideOfflineModal(){
         this.$emit('closeTimeout',{'closer':1,'load':0});
    }
  }
}
</script>


