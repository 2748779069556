<template>
   <div class="login-page full-height therapist-flow">
    <div class="login-right">
      <div class="login-right-inner wow pulse animate" style="animation-duration: 1s;">
      <h3>LOGIN</h3>
      <div class="login-from">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>EMAIL</label>
                <input type="text" v-model="user.email_phone" id ="email">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>PASSWORD</label>
                <input v-if="show_password" type="text" v-model="user.password" id ="password">
                <input v-if="hide_password" type="password" v-model="user.password" id ="password" autocomplete="true">
                <img src="../assets/images/eye.svg" style="cursor: pointer;" v-show="hide_password"  @mouseover="showPasswordMethod">
                <img src="../assets/images/showpassword.png" style="cursor: pointer;height: 20px;" v-show="show_password"  @mouseleave="hidePasswordMethod">
              </div>
            </div>
            <div class="col-md-12 forget-pass">
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                   Remember Me
                  </label>
                </div>
              </div>
            </div>
              <div class="col-md-12">
              <div class="form-group">
                <button class="cm-btn" @click.prevent="login" :disabled="isDisabled"><span>Login</span></button>
              </div>
            </div>
        </div>
        </form>
      </div>
      </div>
    </div>
  </div>
</template>
 <script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
    data() {
        return {
            user: {
                email_phone: '',
                password: '',
                fcm_id:'',
            },
            hide_password:true,
            isDisabled:false,
            show_password:false,
        };
    },
    mounted(){
      Auth.check();
    },
    methods: {

        login() {
            Auth.check();
            this.isDisabled = true;
            this.user.fcm_id = window.localStorage.getItem('fcm_id') ? window.localStorage.getItem('fcm_id') : null;
            let login_url = Constant.getbaseurl()+'/therapist/login';
            if(this.checkFields()){
                this.axios.post(login_url, this.user)
                  .then(({data}) => {
                      this.isDisabled = false;
                      this.$swal({icon: 'success', title: "Therapist Login Successfully"});
                      let response_data = data.data;
                      Auth.login(response_data.token,response_data); //set local storage
                      this.$router.go('/');
                  })
                  .catch((error) => {
                      this.isDisabled = false;
                      this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  });
            }else{
             this.isDisabled = false;
            }
        },
        checkFields(){
            if(!this.user.email_phone){
              this.$swal({icon: 'error', title: "Please Enter Email",background:'#cb1111',color:'#fff'});
              return false;
            }
            if(!this.user.password){
              this.$swal({icon: 'error', title: "Please Enter Password",background:'#cb1111',color:'#fff'});
              return false;
            }
            return true;
        },
        showPasswordMethod(){
          this.hide_password = false;
          this.show_password = true;
        },
        hidePasswordMethod(){
           this.hide_password = true;
          this.show_password = false;
        }
    }
}
</script>


